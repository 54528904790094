import React, { FC } from 'react'
import styled from 'styled-components'
import { colors } from '../../../util/colors'

const TokenWrapper = styled.div`
  font-family: 'Roboto Mono', monospace;
  span {
    color: ${colors.tokenColor};
  }
`

interface IProps {
  token: string
}

export const Token: FC<IProps> = ({ token }) => {
  const tokenCloseable = (tokenString: string) => {
    return tokenString.split('').reduce((acc: string, current: string) => {
      let accumulator = acc
      if (Number.isInteger(+current)) {
        accumulator += `<span>${current}</span>`
      } else {
        accumulator += current
      }
      return accumulator
    }, '')
  }
  return <TokenWrapper dangerouslySetInnerHTML={{ __html: tokenCloseable(token) }} />
}
