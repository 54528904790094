import React, { FC } from 'react'
import styled from 'styled-components'

import { Button } from 'antd'
import { images } from '../../../assets'

const Container = styled.div`
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
`
const BlurBg = styled.div`
  position: absolute;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: saturate(180%) blur(80px);
  height: 100%;
  width: 100%;
  z-index: 0;
`
const LoginBoxContainer = styled.div`
  background: rgb(0 0 0 / 30%);
  border-radius: 5px;
  width: 600px;
  height: 400px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1999;
`
const LoginBox = styled.div`
  position: absolute;
  z-index: 1;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
const ButtonWrapper = styled.div`
  margin: 8px;
`
const LogoImage = styled.img`
  width: 200px;
  margin-bottom: 32px;
  stroke: transparent;
  stroke-width: 0px;
`
const DecorOne = styled.img`
  width: 1000px;
  left: 0;
  z-index: 0;
  position: absolute;
`
const DecorTwo = styled.img`
  width: 1000px;
  left: 400px;
  z-index: 0;
  position: absolute;
  transform: rotate(45deg);
`
const DecorThree = styled.img`
  width: 1000px;
  right: 0;
  z-index: 0;
  position: absolute;
  transform: rotate(90deg);
`
const TextSignIn = styled.p`
  font-size: 22px;
  color: white;
`
const TextSignInCC = styled.p`
  font-size: 12px;
  color: white;
`

export const Login: FC = () => (
  <Container className="wrapper">
    <DecorOne src={images.decorsvg} />
    <DecorTwo src={images.decorensvg} />
    <DecorThree src={images.decorstsvg} />
    <BlurBg />
    <LoginBoxContainer>
      <LoginBox>
        <LogoImage src={images.logo} alt="Logo" />
        <TextSignIn>Sign in</TextSignIn>
        <ButtonWrapper>
          <Button type="primary">Login with Google</Button>
        </ButtonWrapper>
        <ButtonWrapper>
          <Button type="primary">Login with Github</Button>
        </ButtonWrapper>
        <TextSignInCC>
          Subject to the Google Privacy Policy and Terms of Service.
        </TextSignInCC>
      </LoginBox>
    </LoginBoxContainer>
  </Container>
)
