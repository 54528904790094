import React, { FC, useContext } from 'react'
import { Modal, Typography } from 'antd'
import { AuthContext } from '../../../context/AuthContext'
import { messageKeys } from '../../../util/en'

const { Link, Text } = Typography

const OrganizationMissingModal: FC = () => {
  const { userData, keycloak } = useContext(AuthContext)
  const isVisible = userData?.orgs && !userData.orgs.length
  return (
    <Modal
      title={messageKeys.notPartOfAnOrganization}
      centered
      visible={isVisible}
      width={1000}
      closable={false}
      onOk={() => keycloak.logout()}
      okText="Log out"
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <Text>
        {messageKeys.contactAdminForAddingYou}, or write to us at &nbsp;
        <Link href="mailto:support@gateway.fm" target="_blank">
          support@gateway.fm
        </Link>
      </Text>
    </Modal>
  )
}

export default OrganizationMissingModal
