/* eslint import/no-unresolved: "off", curly: "error" */
import React, { FC } from 'react'
import { Modal, Button } from 'antd'

import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { UseMutationResult } from 'react-query/types/react/types'
import { messageKeys } from '../../../util/en'
import { ProjectCreateEditModal } from '../../projects/components/ProjectCreateEditModal'
import { useProjects } from '../../projects/useProjects'
import { IProjectsData } from '../../../types/commonInterfaces'

const { confirm } = Modal

const Title: FC<{ name: string }> = ({ name }) => (
  <p style={{ fontWeight: 400 }}>
    {messageKeys.deleteProjectTitle} &nbsp;
    <strong>{name}</strong>?
  </p>
)

interface IProps {
  deleteProject: UseMutationResult
  projectData: IProjectsData
}

export const DetailsHeader: FC<IProps> = ({ deleteProject, projectData }) => {
  const history = useHistory()
  const {
    showModal,
    hideModal,
    isModalVisible,
    form,
    projectFormSubmit,
    projectPlatforms,
    region,
  } = useProjects()

  const handleCancel = () => {
    form.resetFields()
    hideModal()
  }
  const deleteCurrentProject = () => {
    deleteProject.mutate(projectData.ID)
    handleCancel()
    history.goBack()
  }

  const showDeleteConfirm = () => {
    confirm({
      title: <Title name={projectData.Name} />,
      icon: <ExclamationCircleOutlined />,
      content: messageKeys.deleteProjectContent,
      width: 500,
      okText: (
        <span data-testid="confirmDeleteProjBtn">{messageKeys.yesDelete}</span>
      ),
      okType: 'danger',
      cancelText: <span data-testid="confirmKeepProjBtn">{messageKeys.noKeep}</span>,
      onOk() {
        deleteCurrentProject()
      },
      onCancel() {
        // console.log('onCancel')
      },
    })
  }

  return (
    <>
      {isModalVisible && (
        <ProjectCreateEditModal
          projectData={projectData}
          isModalVisible={isModalVisible}
          handleCancel={handleCancel}
          form={form}
          projectPlatforms={projectPlatforms}
          projectFormSubmit={projectFormSubmit}
        />
      )}
      <div>
        <Button
          onClick={() => showModal(region)}
          style={{ margin: 4 }}
          key={1}
          data-testid="editProjectBtn"
        >
          <EditOutlined />
          Edit project
        </Button>
        <Button
          danger
          style={{ margin: 4 }}
          onClick={showDeleteConfirm}
          key={2}
          data-testid="deleteProjectBtn"
        >
          <DeleteOutlined />
          Delete project
        </Button>
      </div>
    </>
  )
}
