import {
  Form,
  Input,
  Modal,
  Radio,
  Button,
  FormInstance,
  RadioChangeEvent,
} from 'antd'
import React, { FC, useContext, useMemo, useState } from 'react'
import { messageKeys } from '../../../util/en'
import {
  IPlatformsWithNetwork,
  IProjectsData,
} from '../../../types/commonInterfaces'
import { TypeChoosingProjectCreation } from './TypeChoosingProjectCreation'
import { titleCreationHelper } from '../../../util/titleCreationHelper'
import { ENVIRONMENTS_LANE } from '../../../util/constants'
import { PlatformChooseProjectCreation } from './PlatformChooseProjectCreation'
import { EProjectEnvironment, TProjectTypeName } from '../enums'
import { AuthContext } from '../../../context/AuthContext'

interface IProjectModal {
  projectData: IProjectsData | null
  isModalVisible: boolean
  handleCancel: () => void
  projectFormSubmit: (pd: IProjectsData | Record<string, unknown>) => void
  form: FormInstance
  projectPlatforms: IPlatformsWithNetwork
}

export const ProjectCreateEditModal: FC<IProjectModal> = ({
  projectData,
  isModalVisible,
  handleCancel,
  projectPlatforms,
  projectFormSubmit,
  form,
}) => {
  const { region } = useContext(AuthContext)
  const [isInitialStep, setIsInitialStep] = useState(false)

  const [projectType, setProjectType] = useState<TProjectTypeName | null>(null)

  const isEdit = !!projectData

  const onProjectTypeChoose = (event: RadioChangeEvent) => {
    setProjectType(event.target.value)
  }
  const onOK = () =>
    isEdit ? projectFormSubmit(projectData) : projectFormSubmit({})

  const laneInitialValue = useMemo(() => {
    if (!isEdit) {
      return EProjectEnvironment.dev
    }

    if (projectData?.Lane === EProjectEnvironment.oldDel) {
      return EProjectEnvironment.dev
    }
    return projectData?.Lane
  }, [isEdit])

  const title = `${titleCreationHelper(
    isEdit,
    isInitialStep,
    projectType
  )} in ${region} region`
  const okText = isEdit ? messageKeys.update : messageKeys.create
  const footer =
    isInitialStep && !isEdit
      ? [
          <Button
            type="primary"
            data-testid="createProjectNextBtn"
            onClick={() => setIsInitialStep(false)}
            key="nextCreateProjectBtn"
            disabled={!projectType}
          >
            {messageKeys.next}
          </Button>,
        ]
      : [
          <Button
            type="primary"
            data-testid="createEditBtn"
            onClick={onOK}
            key="okCreateEditBtn"
          >
            {okText}
          </Button>,
        ]

  return (
    <Modal
      title={title}
      visible={isModalVisible}
      onCancel={handleCancel}
      okText={okText}
      onOk={onOK}
      footer={footer}
      width={770}
    >
      <div data-testid="createEditModal">
        <Form
          name="project"
          form={form}
          layout="horizontal"
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 14 }}
          data-testid="formWrapper"
        >
          {!isEdit && isInitialStep ? (
            <TypeChoosingProjectCreation onProjectTypeChange={onProjectTypeChoose} />
          ) : (
            <>
              <Form.Item
                label={messageKeys.projectLabel}
                name="name"
                initialValue={projectData?.Name}
                tooltip={messageKeys.projectLabel}
                rules={[
                  {
                    required: true,
                    message: messageKeys.enterProjName,
                  },
                  { max: 32, message: messageKeys.projNameLength },
                ]}
              >
                <Input autoFocus data-testid="nameInput" />
              </Form.Item>
              <Form.Item
                label={messageKeys.tag}
                name="lane"
                initialValue={laneInitialValue}
                rules={[
                  {
                    required: true,
                    message: messageKeys.createProjectEnvironmentValidation,
                  },
                ]}
              >
                <Radio.Group>
                  {ENVIRONMENTS_LANE.map((env: string) => (
                    <Radio key={env} value={env} data-testid={`${env}Radio`}>
                      {env}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
              <PlatformChooseProjectCreation
                form={form}
                projectPlatforms={projectPlatforms}
                projectType={projectType}
                isEdit={isEdit}
                projectData={projectData}
              />
            </>
          )}
        </Form>
      </div>
    </Modal>
  )
}
