import React, { FC, Suspense } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { LoadingFallback } from '../components/AppRoutes/LoadingFallback'
import { UnauthRoutes } from './UnaouthRoutes'
import { AppShell } from '../components/AppShell/AppShell'
import { Projects } from '../pages/projects/Projects'
import { ProjectDetails } from '../pages/projectDetails/ProjectDetails'
import { Account } from '../pages/account/Account'
import { Version } from '../pages/version/Version'

export const AppRoutes: FC = () => (
  <Suspense fallback={<LoadingFallback />}>
    <Router>
      <AppShell>
        <Switch>
          <Route exact path="/account">
            <Account />
          </Route>
          <Route exact path="/">
            <Redirect to="/projects" />
          </Route>
          <Route exact path="/projects">
            <Projects />
          </Route>
          <Route exact path="/projects/:projectId">
            <ProjectDetails />
          </Route>
          <Route exact path="/version">
            <Version />
          </Route>
          <UnauthRoutes />
        </Switch>
      </AppShell>
    </Router>
  </Suspense>
)
