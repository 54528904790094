import React, { FC } from 'react'
import { Button, Card } from 'antd'

import styled from 'styled-components'
import { CopyOutlined, CheckOutlined } from '@ant-design/icons'
import {
  IPlatforms,
  IProjectsData,
  TPlatformName,
  TURLNames,
} from '../../../types/commonInterfaces'
import { colors } from '../../../util/colors'
import { messageKeys } from '../../../util/en'
import { ICopiedProtocols } from '../interfaces'
import { EPlatformLabelMap } from '../../projects/enums'

const activeButtonStyle = {
  borderColor: colors.gateway_green,
  color: colors.gateway_green,
}

const UrlContainer = styled.div`
  display: flex;
`

const UrlWrapper = styled.div<{
  isHttp?: boolean
  isHttpArchived?: boolean
  isCopied: boolean
  hasWss?: boolean
}>`
  overflow: auto;
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 2px;
  margin: ${(props: {
    isHttp?: boolean
    isHttpArchived?: boolean
    hasWss?: boolean
  }) => {
    if (props.hasWss) {
      return '0 24px 0 0'
    }
    return '0'
  }};
  background-color: ${(props: { isCopied: boolean; isHttp?: boolean }) =>
    props.isCopied ? colors.tokenCopied : colors.tokenBg};
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:hover {
    transition: background-color 0.3s ease;
    background-color: ${(props: { isCopied: boolean; isHttp?: boolean }) =>
      props.isCopied ? colors.tokenCopied : colors.tokenHoverBg};
  }
`

const UrlText = styled.p`
  padding: 8px 16px;
  margin: 0;
  font-family: Oxygen Mono, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 22px;
  text-overflow: ellipsis;
  overflow: auto;
  white-space: nowrap;
`

const ProtocolWrapper = styled.div<{ isHttp: boolean; isLong?: boolean }>`
  background: ${(props) => (props.isHttp ? '#D6E4FF' : '#FFF1B8')};
  border: ${(props) => (props.isHttp ? '1px solid #ADC6FF' : '1px solid #FFD666')};
  display: flex;
  flex: 1 0 auto;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
  width: ${(props: { isLong?: boolean }) => (props.isLong ? 'auto' : '100px')};
  height: 46px;
  margin: 0;
  position: relative;
`

const FlexWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
`

const OptionWrapper = styled.p`
  text-transform: capitalize;
  margin: 0;
`
const EndpointWrapper = styled.div`
  display: flex;
  align-items: center;
`
const ProtocolTitleStyled = styled.p`
  margin-right: ${(props: { isNear?: boolean }) =>
    props.isNear ? '35px' : undefined};
  margin-bottom: 0;
`

interface IProps {
  copyHttpToClipboard: (isNear?: boolean) => Promise<void>
  copyHttpNonArchivalToClipboard: () => Promise<void>
  copyWssToClipboard: () => Promise<void>
  getUrl: (type: TURLNames) => string | null
  copiedProtocols: ICopiedProtocols
  selectedNetwork: TPlatformName | null
  handleNetworkChange: (s: TPlatformName | null) => void
  data: IProjectsData
}

export const Networks: FC<IProps> = ({
  copyHttpToClipboard,
  copyHttpNonArchivalToClipboard,
  copyWssToClipboard,
  data,
  handleNetworkChange,
  getUrl,
  selectedNetwork,
  copiedProtocols,
}) => {
  const httpUrl = getUrl('HttpUrl')
  const wssUrl = getUrl('WssUrl')
  const nonArchivalHttpUrl = getUrl('NonArchivalHttpUrl')
  const isArchivalHttp = httpUrl?.includes('/archival/')

  const shouldWssBeShown = !!wssUrl

  const networkNameGetter = (el: IPlatforms) =>
    el.ProjectPlatformNetworks &&
    el.ProjectPlatformNetworks[0] &&
    el.ProjectPlatformNetworks[0].Network

  const {
    http: isCopiedHttp,
    ws: isCopiedWs,
    httpArchived: isCopiedHttpArchived,
  } = copiedProtocols

  return (
    <Card
      title={
        <EndpointWrapper data-testid="platformsWrapper">
          <OptionWrapper style={{ marginRight: '12px' }}>Endpoints:</OptionWrapper>
          {data.ProjectPlatforms.filter((el) => el.PlatformName !== 'near').map(
            (el: IPlatforms) => {
              return (
                <Button
                  style={
                    el.PlatformName === selectedNetwork
                      ? activeButtonStyle
                      : undefined
                  }
                  key={`${el.PlatformName}${el.CreatedAt}`}
                  onClick={() => handleNetworkChange(el.PlatformName)}
                  data-testid={`${el.PlatformName}TabBtn`}
                >
                  <span style={{ textTransform: 'capitalize' }}>
                    {EPlatformLabelMap[el.PlatformName]}
                  </span>
                  &nbsp;(
                  {networkNameGetter(el) === 'arb1'
                    ? 'Arbitrum One'
                    : networkNameGetter(el)}
                  )
                </Button>
              )
            }
          )}
        </EndpointWrapper>
      }
      style={{ width: '100%', margin: '32px 0' }}
    >
      <UrlContainer data-testid="urlWrapper">
        {isArchivalHttp && (
          <UrlWrapper
            hasWss={!!wssUrl}
            isHttp
            isCopied={isCopiedHttp}
            onClick={() => copyHttpToClipboard(isArchivalHttp)}
            data-testid="httpsUrlWrapper"
          >
            <FlexWrapper>
              <ProtocolWrapper isHttp isLong={isArchivalHttp}>
                <ProtocolTitleStyled>
                  {isArchivalHttp ? messageKeys.httpsArchival : messageKeys.https}
                </ProtocolTitleStyled>
              </ProtocolWrapper>
              <UrlText data-testid="httpsUrlText">{httpUrl}</UrlText>
            </FlexWrapper>
            <Button type="link">
              {isCopiedHttp ? (
                <CheckOutlined style={{ fontSize: '18px' }} />
              ) : (
                <CopyOutlined style={{ fontSize: '18px' }} />
              )}
            </Button>
          </UrlWrapper>
        )}
        {!isArchivalHttp && (
          <UrlWrapper
            hasWss={!!wssUrl}
            isHttpArchived
            isCopied={isCopiedHttpArchived}
            onClick={copyHttpNonArchivalToClipboard}
            data-testid="httpsUrlNonArchivalWrapper"
          >
            <FlexWrapper>
              <ProtocolWrapper isHttp isLong>
                <ProtocolTitleStyled>
                  {messageKeys.httpsNonArchival}
                </ProtocolTitleStyled>
              </ProtocolWrapper>
              <UrlText data-testid="httpsUrlText">{nonArchivalHttpUrl}</UrlText>
            </FlexWrapper>
            <Button type="link">
              {isCopiedHttpArchived ? (
                <CheckOutlined style={{ fontSize: '18px' }} />
              ) : (
                <CopyOutlined style={{ fontSize: '18px' }} />
              )}
            </Button>
          </UrlWrapper>
        )}
        {shouldWssBeShown && (
          <UrlWrapper
            isCopied={isCopiedWs}
            onClick={copyWssToClipboard}
            data-testid="wssUrlWrapper"
          >
            <FlexWrapper>
              <ProtocolWrapper isHttp={false}>
                {messageKeys.webSocket}
              </ProtocolWrapper>
              <UrlText>{wssUrl}</UrlText>
            </FlexWrapper>
            <Button type="link">
              {isCopiedWs ? (
                <CheckOutlined style={{ fontSize: '18px' }} />
              ) : (
                <CopyOutlined style={{ fontSize: '18px' }} />
              )}
            </Button>
          </UrlWrapper>
        )}
      </UrlContainer>
    </Card>
  )
}
