import React, { FC } from 'react'
import { Button, ConfigProvider, Empty, Popconfirm, Table, SpinProps } from 'antd'
import { IApiKey } from '../../../types/commonInterfaces'
import { colors } from '../../../util/colors'
import { messageKeys } from '../../../util/en'
import { Token } from './Token'
import { images } from '../../../../assets'
import { ITokens } from '../../../types/interfaces'
import { dateTimeToLocaleString } from '../../../util/timeConverter'

interface IColumnsArgs {
  deleteApiKey: (s: string) => void
  setActiveToken: ({ ID, Name }: { ID: string; Name: string }) => void
}

const columnsConstructor = ({ deleteApiKey, setActiveToken }: IColumnsArgs) => [
  {
    title: 'Key label',
    dataIndex: 'Name',
  },
  {
    title: 'API Keys',
    dataIndex: 'PublicKey',
    render: (key: string) => (
      <Token token={`********************************${key}`} />
    ),
  },
  {
    title: 'Created on',
    dataIndex: 'CreatedAt',
    render: (date: Date) => dateTimeToLocaleString(date),
  },
  {
    title: 'Action',
    key: 'action',
    render: ({ ID, Name }: IApiKey) => (
      <>
        <Button
          type="link"
          onClick={() => setActiveToken({ ID, Name })}
          data-testid="tableRowEditBtn"
        >
          {messageKeys.edit}
        </Button>

        <Popconfirm
          title="Are you sure?"
          onConfirm={() => deleteApiKey(ID)}
          okText={messageKeys.yesDelete}
          cancelText={messageKeys.noKeep}
          okButtonProps={{ danger: true }}
        >
          <Button
            type="link"
            style={{ color: colors.delete_red }}
            data-testid="tableRowConfirmDeleteBtn"
          >
            {messageKeys.delete}
          </Button>
        </Popconfirm>
      </>
    ),
  },
]

interface IAPITable {
  isLoading: boolean | SpinProps
  deleteApiKey: (s: string) => void
  setActiveToken: (t: ITokens) => void
  data: IApiKey[]
}
export const ApiTable: FC<IAPITable> = ({
  data,
  isLoading,
  deleteApiKey,
  setActiveToken,
}) => (
  <ConfigProvider
    renderEmpty={() => (
      <Empty
        description={messageKeys.emptyProjectKeysText}
        image={images.projectDetailsKey}
        style={{
          marginTop: '35px',
          marginBottom: '32px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          lineHeight: '22px',
        }}
        imageStyle={{
          height: '38px',
          marginBottom: '6px',
        }}
      />
    )}
  >
    <Table
      columns={columnsConstructor({ deleteApiKey, setActiveToken })}
      dataSource={data}
      loading={isLoading}
      rowKey="ID"
      pagination={data?.length <= 10 ? false : undefined}
    />
  </ConfigProvider>
)
