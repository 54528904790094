import React, { FC } from 'react'
import { Button, Card, Tabs, Typography } from 'antd'
import { PageHeader } from '@ant-design/pro-layout'
import styled from 'styled-components'
import { ApiTable } from './components/ApiTable'
import { useProjectDetails } from './useProjectDetails'
import { DetailsHeader } from './components/DetailsHeader'
import { Networks } from './components/Networks'
import { ApiKeyDetailsDialog } from './components/ApiKeyDetailsDialog'
import LoadingComponent from '../../components/AppShell/Common/LoadingComponent'
import { DateAndLane } from './components/DateAndLane'
import { messageKeys } from '../../util/en'
import { StepCard } from './components/StepCard'

const { Paragraph } = Typography
const gridStyle: React.CSSProperties = {
  width: `${100 / 3}%`,
}
const curlBuilder = (url: string, platform: string) => {
  const platformCurl: { [key: string]: string } = {
    ethereum: `curl --location --request POST '${url}?apiKey=<API_KEY>' \\
 --header 'Content-Type: application/json' \\
 --data-raw '{
      "jsonrpc": "2.0",
      "id": 0,
      "method": "eth_blockNumber"
    }'`,
    gnosis: `curl --location --request POST '${url}?apiKey=<API_KEY>' \\
 --header 'Content-Type: application/json' \\
 --data-raw '{
      "jsonrpc": "2.0",
      "id": 0,
      "method": "eth_blockNumber"
    }'`,
    fantom: `curl --location --request POST '${url}?apiKey=<API_KEY>' \\
 --header 'Content-Type: application/json' \\
 --data-raw '{
      "jsonrpc": "2.0",
      "id": 0,
      "method": "eth_blockNumber"
    }'`,
    near: `curl --location --request POST '${url}?apiKey=<API_KEY>' \\
    --header 'Content-Type: application/json' \\
    --data-raw '{
      "jsonrpc": "2.0",
      "id": "11",
      "method": "block",
      "params": {
        "finality": "final"
      }
    }'`,
    zkevm: `curl --location --request POST '${url}?apiKey=<API_KEY>' \\
 --header 'Content-Type: application/json' \\
 --data-raw '{
      "jsonrpc": "2.0",
      "id": 0,
      "method": "eth_blockNumber"
    }'`,
    arbitrum: `curl --location --request POST '${url}?apiKey=<API_KEY>' \\
 --header 'Content-Type: application/json' \\
 --data-raw '{
      "jsonrpc": "2.0",
      "id": 0,
      "method": "eth_blockNumber"
    }'`,
    soroban: `curl --location --request POST '${url}?apiKey=<API_KEY>' \\
 --header 'Content-Type: application/json' \\
 --data-raw '{
        "jsonrpc": "2.0",
        "method": "getHealth",
        "id": 0
        }'`,
    lukso: `curl --location --request POST '${url}?apiKey=<API_KEY>' \\
 --header 'Content-Type: application/json' \\
 --data-raw '{
      "jsonrpc": "2.0",
      "id": 0,
      "method": "eth_blockNumber"
    }'`,
    bnb: `curl --location --request POST '${url}?apiKey=<API_KEY>' \\
 --header 'Content-Type: application/json' \\
 --data-raw '{
      "jsonrpc": "2.0",
      "id": 0,
      "method": "eth_blockNumber"
    }'`,
    palm: `curl --location --request POST '${url}?apiKey=<API_KEY>' \\
 --header 'Content-Type: application/json' \\
 --data-raw '{
      "jsonrpc": "2.0",
      "id": 0,
      "method": "eth_blockNumber"
    }'`,
    base: `curl --location --request POST '${url}?apiKey=<API_KEY>' \\
    --header 'Content-Type: application/json' \\
    --data-raw '{
         "jsonrpc": "2.0",
         "id": 0,
         "method": "eth_blockNumber"
       }'`,
    polygon: `curl --location --request POST '${url}?apiKey=<API_KEY>' \\
 --header 'Content-Type: application/json' \\
 --data-raw '{
      "jsonrpc": "2.0",
      "id": 0,
      "method": "eth_blockNumber"
    }'`,
    optimism: `curl --location --request POST '${url}?apiKey=<API_KEY>' \\
 --header 'Content-Type: application/json' \\
 --data-raw '{
      "jsonrpc": "2.0",
      "id": 0,
      "method": "eth_blockNumber"
    }'`,
    gnosispay: `curl --location --request POST '${url}?apiKey=<API_KEY>' \\
 --header 'Content-Type: application/json' \\
 --data-raw '{
      "jsonrpc": "2.0",
      "id": 0,
      "method": "eth_blockNumber"
    }'`,
  }
  return platformCurl[platform]
}

const urlWithAuthHeaderBuilder = (
  url: string,
  platform: string,
  apiKey: { SecretKey?: string; PublicKey: string }
) => {
  const platformCurl: { [key: string]: string } = {
    ethereum: `curl --location --request POST '${url}' \\
 --header 'Content-Type: application/json' \\
  --header 'Authorization: Bearer ${
    apiKey?.SecretKey ? `${apiKey.SecretKey}.${apiKey.PublicKey}` : '<API_KEY>'
  }' \\
 --data-raw '{
      "jsonrpc": "2.0",
      "id": 0,
      "method": "eth_blockNumber"
    }'`,
    gnosis: `curl --location --request POST '${url}' \\
 --header 'Content-Type: application/json' \\
 --header 'Authorization: Bearer ${
   apiKey?.SecretKey ? `${apiKey.SecretKey}.${apiKey.PublicKey}` : '<API_KEY>'
 }' \\
 --data-raw '{
      "jsonrpc": "2.0",
      "id": 0,
      "method": "eth_blockNumber"
    }'`,
    fantom: `curl --location --request POST '${url}' \\
 --header 'Content-Type: application/json' \\
 --header 'Authorization: Bearer ${
   apiKey?.SecretKey ? `${apiKey.SecretKey}.${apiKey.PublicKey}` : '<API_KEY>'
 }' \\
 --data-raw '{
      "jsonrpc": "2.0",
      "id": 0,
      "method": "eth_blockNumber"
    }'`,
    near: `curl --location --request POST '${url}' \\
    --header 'Content-Type: application/json' \\
    --header 'Authorization: Bearer ${
      apiKey?.SecretKey ? `${apiKey.SecretKey}.${apiKey.PublicKey}` : '<API_KEY>'
    }' \\
    --data-raw '{
      "jsonrpc": "2.0",
      "id": "11",
      "method": "block",
      "params": {
        "finality": "final"
      }
    }'`,
    zkevm: `curl --location --request POST '${url}' \\
 --header 'Content-Type: application/json' \\
  --header 'Authorization: Bearer ${
    apiKey?.SecretKey ? `${apiKey.SecretKey}.${apiKey.PublicKey}` : '<API_KEY>'
  }' \\
 --data-raw '{
      "jsonrpc": "2.0",
      "id": 0,
      "method": "eth_blockNumber"
    }'`,
    arbitrum: `curl --location --request POST '${url}' \\
 --header 'Content-Type: application/json' \\
  --header 'Authorization: Bearer ${
    apiKey?.SecretKey ? `${apiKey.SecretKey}.${apiKey.PublicKey}` : '<API_KEY>'
  }' \\
 --data-raw '{
      "jsonrpc": "2.0",
      "id": 0,
      "method": "eth_blockNumber"
    }'`,
    soroban: `curl --location --request POST '${url}' \\
 --header 'Content-Type: application/json' \\
  --header 'Authorization: Bearer ${
    apiKey?.SecretKey ? `${apiKey.SecretKey}.${apiKey.PublicKey}` : '<API_KEY>'
  }' \\
 --data-raw '{
        "jsonrpc": "2.0",
        "method": "getHealth",
        "id": 0
        }'`,
    lukso: `curl --location --request POST '${url}' \\
 --header 'Content-Type: application/json' \\
  --header 'Authorization: Bearer ${
    apiKey?.SecretKey ? `${apiKey.SecretKey}.${apiKey.PublicKey}` : '<API_KEY>'
  }' \\
 --data-raw '{
      "jsonrpc": "2.0",
      "id": 0,
      "method": "eth_blockNumber"
    }'`,
    bnb: `curl --location --request POST '${url}' \\
 --header 'Content-Type: application/json' \\
  --header 'Authorization: Bearer ${
    apiKey?.SecretKey ? `${apiKey.SecretKey}.${apiKey.PublicKey}` : '<API_KEY>'
  }' \\
 --data-raw '{
      "jsonrpc": "2.0",
      "id": 0,
      "method": "eth_blockNumber"
    }'`,
    palm: `curl --location --request POST '${url}' \\
 --header 'Content-Type: application/json' \\
  --header 'Authorization: Bearer ${
    apiKey?.SecretKey ? `${apiKey.SecretKey}.${apiKey.PublicKey}` : '<API_KEY>'
  }' \\
 --data-raw '{
      "jsonrpc": "2.0",
      "id": 0,
      "method": "eth_blockNumber"
    }'`,
    base: `curl --location --request POST '${url}' \\
    --header 'Content-Type: application/json' \\
     --header 'Authorization: Bearer ${
       apiKey?.SecretKey ? `${apiKey.SecretKey}.${apiKey.PublicKey}` : '<API_KEY>'
     }' \\
    --data-raw '{
         "jsonrpc": "2.0",
         "id": 0,
         "method": "eth_blockNumber"
       }'`,
    polygon: `curl --location --request POST '${url}' \\
 --header 'Content-Type: application/json' \\
  --header 'Authorization: Bearer ${
    apiKey?.SecretKey ? `${apiKey.SecretKey}.${apiKey.PublicKey}` : '<API_KEY>'
  }' \\
 --data-raw '{
      "jsonrpc": "2.0",
      "id": 0,
      "method": "eth_blockNumber"
    }'`,
    optimism: `curl --location --request POST '${url}' \\
 --header 'Content-Type: application/json' \\
  --header 'Authorization: Bearer ${
    apiKey?.SecretKey ? `${apiKey.SecretKey}.${apiKey.PublicKey}` : '<API_KEY>'
  }' \\
 --data-raw '{
      "jsonrpc": "2.0",
      "id": 0,
      "method": "eth_blockNumber"
    }'`,
    gnosispay: `curl --location --request POST '${url}' \\
 --header 'Content-Type: application/json' \\
  --header 'Authorization: Bearer ${
    apiKey?.SecretKey ? `${apiKey.SecretKey}.${apiKey.PublicKey}` : '<API_KEY>'
  }' \\
 --data-raw '{
      "jsonrpc": "2.0",
      "id": 0,
      "method": "eth_blockNumber"
    }'`,
  }
  return platformCurl[platform]
}

const TableLabelDiv = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.85);
`

const TableDescriptionDiv = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
`

const TitleStyled = styled.div`
  min-width: 135px;
  text-overflow: ellipsis;
  overflow: auto;
  white-space: nowrap;
`

const { TabPane } = Tabs

export const ProjectDetails: FC = () => {
  const {
    history,
    data,
    isLoading,
    createProjectApiKey,
    copyHttpToClipboard,
    copyHttpNonArchivalToClipboard,
    deleteProject,
    apiKeys,
    copyWssToClipboard,
    handleDeleteApiKey,
    createdApiKey,
    resetApiCreationMutation,
    handleNetworkChange,
    selectedNetwork,
    getUrl,
    copyApiSecretKey,
    isCopied,
    isCreationInLoad,
    form,
    activeToken,
    setActiveToken,
    copiedProtocols,
    resetApiKeyDialog,
    copyUrlCommand,
    isCommandCopied,
  } = useProjectDetails()
  if (isLoading || !data || !data.ProjectPlatforms) {
    return <LoadingComponent />
  }

  const showFirstSteps = false
  return (
    <div>
      <PageHeader
        style={{ paddingLeft: 0, paddingRight: 0 }}
        className="site-page-header"
        onBack={() => history.push('/projects')}
        title={<TitleStyled>{data.Name}</TitleStyled>}
        extra={[
          <DetailsHeader key="1" deleteProject={deleteProject} projectData={data} />,
        ]}
        subTitle={<DateAndLane projectData={data} />}
      />
      <Tabs defaultActiveKey="1" data-testid="projDetailsTabs">
        <TabPane tab="Endpoints & API Keys" key="endpoints">
          <Networks
            copyHttpToClipboard={copyHttpToClipboard}
            copyHttpNonArchivalToClipboard={copyHttpNonArchivalToClipboard}
            copyWssToClipboard={copyWssToClipboard}
            handleNetworkChange={handleNetworkChange}
            selectedNetwork={selectedNetwork}
            copiedProtocols={copiedProtocols}
            getUrl={getUrl}
            data={data}
          />
          {showFirstSteps && (
            <Card title={messageKeys.firstSteps} bodyStyle={{ display: 'flex' }}>
              <Card.Grid hoverable={false} style={gridStyle}>
                <StepCard
                  title={messageKeys.yourFirstSteps}
                  description={messageKeys.copyPastCode}
                >
                  <Paragraph
                    copyable={{
                      text: curlBuilder(
                        getUrl('HttpUrl') || getUrl('NonArchivalHttpUrl'),
                        selectedNetwork
                      ),
                    }}
                    style={{
                      textAlign: 'left',
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <pre>
                      {curlBuilder(
                        getUrl('HttpUrl') || getUrl('NonArchivalHttpUrl'),
                        selectedNetwork
                      )}
                    </pre>
                  </Paragraph>
                </StepCard>
              </Card.Grid>
              <Card.Grid hoverable={false} style={gridStyle}>
                <StepCard
                  title={messageKeys.popularMethods}
                  description={messageKeys.authViaHeader}
                >
                  <Paragraph
                    copyable={{
                      text: urlWithAuthHeaderBuilder(
                        getUrl('HttpUrl') || getUrl('NonArchivalHttpUrl'),
                        selectedNetwork,
                        createdApiKey
                      ),
                    }}
                    style={{
                      textAlign: 'left',
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <pre>
                      {urlWithAuthHeaderBuilder(
                        getUrl('HttpUrl') || getUrl('NonArchivalHttpUrl'),
                        selectedNetwork,
                        createdApiKey
                      )}
                    </pre>
                  </Paragraph>
                </StepCard>
              </Card.Grid>
              <Card.Grid hoverable={false} style={gridStyle}>
                <StepCard
                  title={messageKeys.learnWhatCanDo}
                  description={messageKeys.docApiGtw}
                >
                  <a
                    href={`https://docs.gateway.fm/${selectedNetwork}`}
                    target="_blank"
                    rel="noreferrer"
                    style={{ marginTop: '44px' }}
                  >
                    Documentation
                  </a>
                </StepCard>
                <StepCard
                  title={messageKeys.gettingHelp}
                  description={messageKeys.expTechIssuesCont}
                >
                  <a href="mailto:support@gateway.fm" style={{ marginTop: '44px' }}>
                    Contact Us
                  </a>
                </StepCard>
              </Card.Grid>
            </Card>
          )}
          <TableDescriptionDiv>
            <TableLabelDiv>API keys</TableLabelDiv>
            <Button
              key="1"
              type="primary"
              data-testid="createApiBtn"
              disabled={apiKeys.length >= 100 || isCreationInLoad}
              onClick={createProjectApiKey}
            >
              Add new API key
            </Button>
          </TableDescriptionDiv>
          <ApiTable
            data={apiKeys}
            isLoading={{
              spinning: isLoading,
              indicator: <LoadingComponent />,
            }}
            deleteApiKey={handleDeleteApiKey}
            setActiveToken={setActiveToken}
          />

          <ApiKeyDetailsDialog
            copyApiSecretKey={copyApiSecretKey}
            createdApiKey={createdApiKey || activeToken}
            form={form}
            isCopied={isCopied}
            resetApiCreationMutation={resetApiCreationMutation}
            resetApiKeyDialog={resetApiKeyDialog}
            selectedNetwork={selectedNetwork}
            getUrl={getUrl}
            urlWithAuthHeaderBuilder={urlWithAuthHeaderBuilder}
            copyUrlCommand={copyUrlCommand}
            isCommandCopied={isCommandCopied}
          />
        </TabPane>
      </Tabs>
    </div>
  )
}
