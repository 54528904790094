const DATA_REGION = 'data-region'

export const setItemToLocalStorage = (key: string, value: string): void => {
  localStorage.setItem(key, value)
}

export const getItemFromLocalStorage = (key: string): string => {
  return localStorage.getItem(key)
}

export const removeItemFromLocalStorage = (key: string): void => {
  localStorage.removeItem(key)
}

export const getRegionFromLocalStorage = (): string => {
  return getItemFromLocalStorage(DATA_REGION)
}

export const setRegionToLocalStorage = (value: string): void => {
  setItemToLocalStorage(DATA_REGION, value)
}
