export enum EPlatformTagColors {
  Purple = 'purple',
  Blue = 'blue',
  Green = 'green',
  Red = 'red',
  Volcano = 'volcano',
  GeekBlue = 'geekblue',
  Orange = 'orange',
  Magenta = 'magenta',
  Cyan = 'cyan',
  Yellow = 'yellow',
}

export enum EPlatformNames {
  Near = 'near',
  Ethereum = 'ethereum',
  Fantom = 'fantom',
  Gnosis = 'gnosis',
  Zkevm = 'zkevm',
  Arbitrum = 'arbitrum',
  Soroban = 'soroban',
  Polygon = 'polygon',
  Optimism = 'optimism',
  Lukso = 'lukso',
  Gnosispay = 'gnosispay',
  Bnb = 'bnb',
  Palm = 'palm',
  Base = 'base',
}

export enum EProjectType {
  Rpc = 'RPC',
}
export enum EProjectEnvironment {
  dev = 'Development',
  prod = 'Production',
  oldDel = 'dev',
}

export enum EPlatformLabelMap {
  ethereum = 'Ethereum',
  near = 'Near',
  fantom = 'Fantom',
  gnosis = 'Gnosis',
  zkevm = 'Polygon zkEVM',
  arbitrum = 'Arbitrum',
  soroban = 'soroban (stellar)',
  polygon = 'Polygon',
  optimism = 'Optimism',
  lukso = 'Lukso',
  gnosispay = 'Gnosispay',
  bnb = 'BNB',
  palm = 'Palm',
  base = 'Base',
}

export type TProjectTypeName = keyof typeof EProjectType
