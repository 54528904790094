import React, { FC, useContext } from 'react'
import { Avatar, Button, Dropdown, Layout, Menu, Skeleton, Typography } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { Link, useHistory } from 'react-router-dom'
import { MainContent } from './MainContent'
import { AuthContext } from '../../../context/AuthContext'
import { images } from '../../../../assets'
import { Logo, LogoWrapper } from './Sidebar'
import { colors } from '../../../util/colors'
import { TReactChildren } from '../../../types/types'
import { useErrorContext } from '../../../context/ErrorContext'
import { isDevelopmentEnvironmentServed } from '../../../util/environment'
import { DevTag } from './DevTag'
import { useServices } from '../../../services/useServices'

const { Header, Footer } = Layout

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const UserName = styled.p`
  font-size: 16px;
  margin: 0;
  margin-left: 8px;
  color: black;
`

const DivLeftSide = styled.div`
  display: flex;
  align-items: center;
`

const { Text } = Typography

const MenuItems = () => {
  const { logout } = useContext(AuthContext)
  const { userData } = useContext(AuthContext)
  const { getUserInfo } = useServices()
  getUserInfo()
  return (
    <Menu>
      <Menu.Item key="1">
        <Text keyboard>{userData?.orgs[0]}</Text>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="2">
        <Link to="/account" data-testid="profileLink">
          Profile
        </Link>
      </Menu.Item>
      <Menu.Item key="3">
        <a
          href="https://discord.com/invite/CgYXY97xWY"
          target="_blank"
          data-testid="discordLink"
          rel="noreferrer"
        >
          Gateway Discord
        </a>
      </Menu.Item>
      <Menu.Item key="4">
        <a
          href="https://docs.gateway.fm/"
          target="_blank"
          data-testid="helplink"
          rel="noreferrer"
        >
          Help
        </a>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="5" onClick={logout} data-testid="logoutLink">
        Logout
      </Menu.Item>
    </Menu>
  )
}

export const HeaderFooterLayout: FC<TReactChildren> = ({ children }) => {
  const { userData } = useContext(AuthContext)
  const history = useHistory()
  const { resetError } = useErrorContext()

  const onLogoClick = () => {
    resetError()
    history.push('/projects')
  }
  const shouldDevTagBeVisible = isDevelopmentEnvironmentServed()

  return (
    <Layout>
      <Header
        className="site-layout-background"
        style={{
          padding: '0 32px 0 0',
          justifyContent: 'space-between',
          alignItems: 'center',
          display: 'flex',
          height: '56px',
          background: colors.headerBg,
        }}
      >
        <LogoWrapper onClick={onLogoClick}>
          <Logo src={images.logoBlack} alt="Gateway Fm logo" />
          {shouldDevTagBeVisible && <DevTag />}
        </LogoWrapper>
        <DivLeftSide>
          <Dropdown overlay={MenuItems()} trigger={['click']}>
            <Button
              type="link"
              data-testid="avatarBtn"
              style={{ height: '100%' }}
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              <UserInfo>
                {userData && (
                  <Avatar
                    size={32}
                    icon={<UserOutlined />}
                    src={userData.profile_pic}
                  />
                )}
                {userData ? (
                  <UserName>{userData.given_name}</UserName>
                ) : (
                  <Skeleton />
                )}
              </UserInfo>
            </Button>
          </Dropdown>
        </DivLeftSide>
      </Header>
      <MainContent>{children}</MainContent>
      <Footer style={{ textAlign: 'center' }}>
        Gateway FM ©2023 | <a href="mailto:support@gateway.fm">Contact Us</a>
      </Footer>
    </Layout>
  )
}
