import { Button, Input } from 'antd'
import React, { FormEvent, useState, FC, useEffect } from 'react'
import styled from 'styled-components'
import { ClearOutlined } from '@ant-design/icons'

const { Search } = Input

export interface IPlainObject {
  [key: string]: string
}

interface IProps<> {
  setFilterValue: (value: string) => void
  placeHolder: string
}

const SearchStyled = styled(Search)`
  // width: 20%;
  margin: 10px 0;
`
const DivStyled = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 250px;
`

export const TableSearchInput: FC<IProps> = ({ setFilterValue, placeHolder }) => {
  const [value, setValue] = useState('')
  useEffect(() => {
    setFilterValue(value)
  }, [value])

  const onClear = () => setValue('')
  return (
    <DivStyled>
      <Button icon={<ClearOutlined />} onClick={onClear} />
      <SearchStyled
        placeholder={placeHolder}
        value={value}
        onChange={(e: FormEvent<HTMLInputElement>) => {
          const currValue = e.currentTarget.value
          setValue(currValue)
        }}
      />
    </DivStyled>
  )
}
