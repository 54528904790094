/* eslint-disable spellcheck/spell-checker */
export const messageKeys: {
  [key: string]: string
} = {
  copySecretToken: 'Secret token has been copied!',
  copyCommand: 'Command Copied!',
  copyHttp: 'HTTP url copied to the clipboard!',
  copyHttpNonArchival: 'HTTP (non-archival) url copied to the clipboard!',
  copyHttpArchival: 'HTTP (archival) url copied to the clipboard!',
  copyWss: 'WSS url copied to the clipboard!',
  somethingWentWrong: 'Something went wrong!',
  somethingWentWrongErr: 'Something went wrong',
  deleteProjectTitle: 'Are you sure you want to delete',
  deleteProjectContent:
    'All API keys belonging to this project will also be deleted. Before deleting, please make sure any processes using these API keys have been reconfigured. This action cannot be undone.\n',
  yes: 'Yes',
  no: 'No',
  enterProjName: "Please enter project's name",
  projNameLength: 'Project name cannot be longer than 32 characters',
  selectPlatform: 'Please select a platform',
  create: 'Create',
  next: 'Next',
  createProjTitle: 'Create project',
  createProjChooseTypeTitle: 'Select type of the project',
  createProjectEnvironmentValidation: 'Please choose an environment!',
  projects: 'Projects',
  projectLabel: 'Project name',
  tag: 'Tag',
  projectPlatform: 'Project platform',
  update: 'Update',
  updateProj: 'Update project',
  accountDetails: 'Account details',
  makeCopy: 'Don’t forget to copy the Key. You won’t be able to see it again.',
  apiKeyCreated: 'API key created successfully',
  projectLimitsTooltip: 'You’ve reached your limit of 10 projects',
  emptyProjectsTableText: 'You don’t have any projects yet',
  emptyProjectKeysText: 'You don’t have any keys yet',
  dontForgetCopy: 'Don’t forget to copy Secret token',
  yesDelete: 'Yes, delete',
  noKeep: 'No, keep',
  editApiKey: 'Edit API key',
  edit: 'Edit',
  delete: 'Delete',
  https: 'HTTPS',
  httpsArchival: 'HTTPS (archival)',
  httpsNonArchival: 'HTTPS (non-archival)',
  error404: 'Error 404: Page not found',
  webSocket: 'WebSocket',
  goBack: 'Go back',
  todayProjectTotal: 'Total requests for this project (today)',
  monthTransferQty:
    'Successful swaps and transfers for your organization (this month)',
  archivalUrl:
    'Archival nodes store full blockchain data, and build an archive of historical states',
  nonArchivalUrl:
    'Non-archival nodes store blockchain data only for the last 5 epochs',

  notPartOfAnOrganization: 'Your Account Is Not Activated Yet',
  contactAdminForAddingYou:
    'To activate your account, please contact our tech support team in the chatroom',
  successMetric1Day: 'Total Successful API calls in the last 24 hours',
  errorMetric1Day: 'Total Failed API calls made in the last 24 hours',
  successMetric7Day: 'Total Successful API calls made in the last 7 days',
  errorMetric7Day: 'Total Failed API calls made in the last 7 days',
  sentMetric1Day: 'Total Websocket calls made in the last 24 hours',
  readMetric1Day:
    'Total Websocket subscription messages received in the last 24 hours',
  sentMetric7Day: 'Total Websocket calls made in the last 7 days',
  readMetric7Day:
    'Total Websocket subscription messages received in the last 7 days',
  learnWhatCanDo: 'Learn what you can do using gateway.fm',
  docApiGtw: 'Check our documentation to see what you can do with Gateway FM API',
  gettingHelp: 'Getting help',
  expTechIssuesCont:
    'If you experience any technical issues with your project or want to change limits for your project',
  yourFirstSteps: 'Your first request',
  popularMethods: 'Popular methods',
  authViaHeader: 'Auth via header',
  firstSteps: 'First steps',
  copyPastCode:
    'Copy and past the code below into terminal and your response should be the current block number',
}

export const messageKeyDynamic: {
  [key: string]: (s: number | string, c?: number) => string
} = {
  projectLimitsText: (length: number, maxCount: number) =>
    `${maxCount - length} out of ${maxCount} new projects available`,
  createProjectTitle: (type: string) => `Create new ${type} project`,
  projectLimitReachedText: (maxCount: number) =>
    `You’ve reached your limit of ${maxCount} projects`,
}
