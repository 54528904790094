/* eslint import/no-unresolved: "off", curly: "error" */
import { useContext } from 'react'
import {
  useIsFetching,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query'
import { message, notification } from 'antd'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { UseMutationResult } from 'react-query/types/react/types'
import { AxiosError } from 'axios'
import { FetchContext } from '../context/FetchContext'
import {
  IApiKey,
  IMetric,
  IOrganizationMetrics,
  IPlatformsWithNetwork,
  IProject,
  IProjectsData,
  ISuccessFailMetric,
  IWebsocketMetrics,
} from '../types/commonInterfaces'
import {
  createKeyApi,
  createProjectApi,
  deleteAccountApi,
  deleteKeyApi,
  deleteProjectApi,
  getKeysApi,
  getOrganizationMetricsApi,
  getProjectApi,
  getProjectPlatformsApi,
  getProjectsApi,
  getProjectSuccessFailMetricsApi,
  getProjectWebsocketMetricsApi,
  setKeyNameApi,
  updateProjectApi,
} from './api'
import { useErrorContext } from '../context/ErrorContext'
import {
  toggleSuccessFailureMetricsEnabled,
  toggleWebsocketMetricsEnabled,
} from '../util/environment'
import { AuthContext } from '../context/AuthContext'
import { messageKeys } from '../util/en'
import {
  SUCCESS_FAILURE_METRICS_INITIAL,
  WEBSOCKET_METRICS_INITIAL,
} from '../util/constants'
import { TEST_ORG } from '../pages/projects/components/PlatformChooseProjectCreation'

export interface IUseServicesReturnType {
  getProjects: (regs: string[]) => UseQueryResult<IProjectsData[], Error>
  getProject: (projectId: string) => { isLoading: boolean; data: IProjectsData }
  getProjectMetrics: (projectData: IProjectsData) => UseQueryResult<IMetric, Error>
  getProjectSuccessFailMetrics: (
    projectId: string,
    projectData: IProjectsData
  ) => UseQueryResult<ISuccessFailMetric, Error>
  getProjectWebsocketMetrics: (
    projectId: string,
    projectData: IProjectsData
  ) => UseQueryResult<IWebsocketMetrics, Error>
  createProject: UseMutationResult<IProjectsData>
  deleteProject: UseMutationResult
  createApiKey: UseMutationResult<IApiKey>
  getApiKeys: (projectId: string) => UseQueryResult<IApiKey[], Error>
  deleteApiKey: UseMutationResult
  getProjectPlatforms: () => UseQueryResult<IPlatformsWithNetwork, Error>
  getOrganizationMetrics: () => UseQueryResult<IOrganizationMetrics[], Error>
  setApiKeyName: UseMutationResult
  isFetching: number
  updateProject: UseMutationResult
  deleteUser: UseMutationResult
  getUserInfo: () => UseQueryResult<any, Error>
}
export const useServices = (): IUseServicesReturnType => {
  const { authAxios, metricsBaseUrl } = useContext(FetchContext)
  const queryClient = useQueryClient()
  const isFetching = useIsFetching()
  const { userData, logout, getUserInfo: getUserInfoKc } = useContext(AuthContext)

  const { triggerError } = useErrorContext()
  const onErrorHandler = (e: AxiosError) => {
    const requestData = e?.response?.data
    // @ts-ignore
    return triggerError(e.message, requestData?.data || '')
  }

  const history = useHistory()
  const { params } = useRouteMatch<{
    projectId: string
  }>()

  const getProjectPlatforms = () =>
    useQuery<IPlatformsWithNetwork, Error>(
      'projectPlatforms',
      async () => getProjectPlatformsApi(authAxios),
      {
        refetchOnMount: false,
      }
    )

  const getUserInfo = () =>
    useQuery<any, Error>(
      'keycloakUser',
      async () => {
        getUserInfoKc()
      },
      {
        refetchOnMount: true,
        refetchOnWindowFocus: true,
      }
    )

  const createProject = useMutation(
    (body: { name: string; platforms: IPlatformsWithNetwork; lane: string }) =>
      createProjectApi(authAxios, body),
    {
      onSuccess: (data) => {
        queryClient.setQueryData('projects', (oldData: IProjectsData[]) => [
          data,
          ...oldData,
        ])
      },
      onSettled: (data: IProjectsData) => {
        if (data.ID) {
          history.push(`projects/${data.ID}`)
        } else {
          triggerError(null)
        }
      },
      onError: (error: AxiosError) => {
        // @ts-ignore
        const errorMessage = error?.response?.data?.data?.err || error.message
        notification.error({
          message: messageKeys.somethingWentWrong,
          description: errorMessage,
        })
      },
    }
  )

  const getProjects = (regions: string[]) =>
    useQuery<IProjectsData[], Error>(
      'projects',
      async () => getProjectsApi(authAxios, regions),
      {
        onSuccess: (data: IProjectsData[]) => {
          data.forEach((project: IProjectsData) => {
            if (params?.projectId !== project.ID) {
              queryClient.setQueryData(['projects', project.ID], project)
            }
          })
        },
        onError: onErrorHandler,
        refetchOnMount: false,
      }
    )

  const getProject = (
    projectId: string
  ): { isLoading: boolean; data: IProjectsData } => {
    const { data, isLoading } = useQuery(
      ['projects', projectId],
      async () => getProjectApi(authAxios, projectId),
      {
        onError: onErrorHandler,
        initialData: () => {
          const queryData: IProjectsData[] = queryClient.getQueryData('projects')
          return queryData?.find(
            (project: IProjectsData) => project.ID === projectId
          )
        },
        refetchOnMount: true,
      }
    )
    return { data, isLoading }
  }

  const updateProject = useMutation(
    (body: {
      name: string
      platforms: IPlatformsWithNetwork
      projectId: string
      lane: string
    }) => updateProjectApi(authAxios, body),
    {
      onSuccess: (data: IProjectsData, { projectId }) => {
        queryClient.setQueryData('projects', (oldData: IProjectsData[] = []) => {
          return oldData.length === 0
            ? [data]
            : oldData.map((project: IProjectsData) => {
                if (project.ID === projectId) {
                  return data
                }
                return project
              })
        })

        queryClient.setQueryData(['projects', projectId], () => data)
      },
    }
  )

  const deleteProject = useMutation(
    (projectId: string) => deleteProjectApi(authAxios, projectId),
    {
      onSuccess: (data) => {
        queryClient.setQueryData('projects', (oldData: IProject[]) =>
          oldData.filter((project: IProject) => project.ID !== data)
        )
        message.success('Project successfully deleted!')
      },
      onError: (error: AxiosError) => {
        if (error.message.includes('403')) {
          message.error('Could not delete the Project')
        } else {
          message.error(`Something went wrong! ${error.message}`)
        }
      },
    }
  )

  const createApiKey = useMutation(
    (body: { projectId: string }) => createKeyApi(authAxios, body),
    {
      onSuccess: (data, { projectId }) => {
        queryClient.setQueryData(
          `apiKey-${projectId}`,
          (oldData: IApiKey[] = []) => [data, ...oldData]
        )
      },
    }
  )

  const getApiKeys = (projectId: string) =>
    useQuery<IApiKey[], Error>(
      `apiKey-${projectId}`,
      async () => getKeysApi(authAxios, projectId),
      {
        onSuccess: (data: IApiKey[]) => {
          data.forEach((apiKey: IApiKey) => {
            queryClient.setQueryData(['apiKey', apiKey.ID], apiKey)
          })
        },
        onError: onErrorHandler,
        refetchOnMount: false,
      }
    )

  const getProjectMetrics = (projectData: IProjectsData) =>
    useQuery<IMetric, Error>(
      `projectMetrics-${projectData ? projectData.ID : 'loading'}`,
      async () => {
        return { Transfers: 0, Swaps: 0 }
      },
      {
        onSuccess: (data: IMetric) => {
          queryClient.setQueryData(
            ['projectMetrics', projectData ? projectData.ID : 'loading'],
            data
          )
        },
        onError: () => {
          message.error(`Something went wrong! Metrics unavailable.`)
        },
        refetchOnMount: false,
        enabled: !!projectData?.ProjectPlatforms[0]?.ProjectPlatformNetworks,
      }
    )

  const getProjectSuccessFailMetrics = (
    projectId: string,
    projectData: IProjectsData
  ) =>
    useQuery<ISuccessFailMetric, Error>(
      `projectSuccessFailureMetrics-${projectData ? projectData.ID : 'loading'}`,
      async () => {
        const shouldFetch = toggleSuccessFailureMetricsEnabled()

        if (shouldFetch && userData?.orgs[0] !== TEST_ORG) {
          return getProjectSuccessFailMetricsApi(
            authAxios,
            projectId,
            metricsBaseUrl
          )
        }
        return SUCCESS_FAILURE_METRICS_INITIAL
      },
      {
        onSuccess: (data: ISuccessFailMetric) => {
          queryClient.setQueryData(
            [
              'projectSuccessFailureMetrics',
              projectId,
              projectData ? projectData.ID : 'loading',
            ],
            data
          )
        },
        onError: () => {
          message.error('Something went wrong! Success/Failure Metrics unavailable')
        },
        refetchOnMount: false,
      }
    )

  const getProjectWebsocketMetrics = (
    projectId: string,
    projectData: IProjectsData
  ) =>
    useQuery<IWebsocketMetrics, Error>(
      `projectWebsocketMetrics-${projectData ? projectData.ID : 'loading'}`,
      async () => {
        const shouldFetch = toggleWebsocketMetricsEnabled()

        if (shouldFetch && userData?.orgs[0] !== TEST_ORG) {
          return getProjectWebsocketMetricsApi(authAxios, projectId, metricsBaseUrl)
        }
        return WEBSOCKET_METRICS_INITIAL
      },
      {
        onSuccess: (data: IWebsocketMetrics) => {
          queryClient.setQueryData(
            [
              'projectWebsocketMetrics',
              projectId,
              projectData ? projectData.ID : 'loading',
            ],
            data
          )
        },
        onError: () => {
          message.error('Something went wrong! Success/Failure Metrics unavailable')
        },
        refetchOnMount: false,
      }
    )

  const deleteApiKey = useMutation(
    ({ apiKeyId }: { apiKeyId: string; projectId: string }) =>
      deleteKeyApi(authAxios, apiKeyId),
    {
      onSuccess: (data, { projectId }) => {
        queryClient.setQueryData(`apiKey-${projectId}`, (oldData: IApiKey[]) =>
          oldData.filter((apiKey: IApiKey) => apiKey.ID !== data)
        )
        message.success('Api key successfully deleted!')
      },
      onError: (error: Error) => {
        if (error.message.includes('403')) {
          message.error('Could not delete the Api Key')
        } else {
          message.error(`Something went wrong! ${error.message}`)
        }
      },
    }
  )

  const setApiKeyName = useMutation(
    ({ apiKeyId, name }: { apiKeyId: string; name: string; projectId: string }) =>
      setKeyNameApi(authAxios, apiKeyId, name),
    {
      onSuccess: (data: { ID: string }, { projectId }) => {
        queryClient.setQueryData(`apiKey-${projectId}`, (oldData: IApiKey[]) => {
          return oldData.map((obj: IApiKey) => (obj.ID === data.ID ? data : obj))
        })
        message.success('Api key successfully updated!')
      },
      onError: (error: Error) => {
        message.error(`Something went wrong! ${error.message}`)
      },
    }
  )

  const getOrganizationMetrics = () =>
    useQuery<IOrganizationMetrics[], Error>(
      ['organizationMetrics', userData?.orgs[0]],
      async () => getOrganizationMetricsApi(authAxios, userData?.orgs[0]),
      {
        refetchOnMount: false,
      }
    )

  const deleteUser = useMutation(
    (userId: string) => deleteAccountApi(authAxios, userId),
    {
      onSuccess: () => {
        message.success('Account successfully deleted!')
        setTimeout(() => logout(), 2000)
      },
      onError: (error: AxiosError) => {
        message.error(`Something went wrong! ${error.message}`)
      },
    }
  )
  return {
    getProjects,
    getProject,
    createProject,
    deleteProject,
    createApiKey,
    getApiKeys,
    deleteApiKey,
    getProjectPlatforms,
    setApiKeyName,
    isFetching,
    updateProject,
    getProjectMetrics,
    getProjectSuccessFailMetrics,
    getProjectWebsocketMetrics,
    getOrganizationMetrics,
    deleteUser,
    getUserInfo,
  }
}
