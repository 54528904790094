export const colors = {
  gateway_green: '#059887',
  green_status: '#52c41a',
  delete_red: '#cf1322',
  headerBg: '#E4EDEC',
  tokenColor: '#0a979c',
  tokenBg: '#f5f5f5',
  tokenHoverBg: '#d9d9d9',
  tokenCopied: 'rgba(5, 152, 135, 0.2)',
}
