import Keycloak from 'keycloak-js'

// @ts-ignore
const keycloak: Keycloak.KeycloakInstance = new Keycloak({
  url: process.env.AUTH_URL || 'http://localhost:8081/auth/',
  realm: process.env.AUTH_REALM || 'gateway',
  clientId: process.env.AUTH_CLIENT_ID || 'gateway-client',
})

export default keycloak
