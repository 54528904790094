import React, { FC, useContext, useState } from 'react'
import { Button, Collapse, Modal, Spin } from 'antd'
import { PageHeader } from '@ant-design/pro-layout'
import { useHistory } from 'react-router-dom'
import {
  ExclamationCircleFilled,
  LoadingOutlined,
  UserOutlined,
} from '@ant-design/icons'
import styled from 'styled-components'
import { AuthContext } from '../../context/AuthContext'
import { messageKeys } from '../../util/en'
import { useServices } from '../../services/useServices'
import { TEST_ORG } from '../projects/components/PlatformChooseProjectCreation'

const { Panel } = Collapse
const { confirm } = Modal

const antIcon = <LoadingOutlined style={{ fontSize: 65, color: 'white' }} spin />

const UserIconWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 34px;
  background: rgba(196, 196, 196, 0.13);
  flex-direction: column;
`
const UserPic = styled.img`
  border: 2px solid #ffffff;
  border-radius: 50% !important;
`

const OverLayLoading = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgb(34 34 34 / 50%);
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Account: FC = () => {
  const [isLoading, setLoading] = useState(false)
  const history = useHistory()
  const { userData } = useContext(AuthContext)
  const { deleteUser } = useServices()
  const showPromiseConfirm = () => {
    confirm({
      title: 'Are you sure you want to delete your account?',
      icon: <ExclamationCircleFilled />,
      okType: 'danger',
      okText: 'Delete',
      content:
        'This action cannot be undone, and all information connected to your account will be permanently deleted.',
      onOk() {
        setLoading(true)
        deleteUser.mutate(userData.sub)
      },
    })
  }

  return (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => history.goBack()}
        title={messageKeys.accountDetails}
      />

      <div
        style={{
          width: 350,
          alignItems: 'center',
          justifyItems: 'center',
          marginBottom: 16,
        }}
      >
        <UserIconWrapper>
          {userData.profile_pic ? (
            <UserPic alt="example" src={userData.profile_pic} />
          ) : (
            <UserOutlined style={{ color: 'white', fontSize: 32 }} />
          )}
          <div style={{ fontSize: 18, fontWeight: 300, margin: 8 }}>
            {userData.name}
          </div>
          <div style={{ fontSize: 14 }}>{userData.email}</div>
        </UserIconWrapper>
      </div>
      {userData?.orgs[0] === TEST_ORG && (
        <Collapse defaultActiveKey={['1']} style={{ width: 350 }}>
          <Panel header="More" key="1">
            <Button danger onClick={showPromiseConfirm}>
              Delete Account
            </Button>
          </Panel>
        </Collapse>
      )}
      {isLoading && (
        <OverLayLoading>
          <Spin
            indicator={antIcon}
            tip={
              <p style={{ fontSize: 32, color: 'white' }}>Deleting the account...</p>
            }
          />
        </OverLayLoading>
      )}
    </div>
  )
}
