import { useHistory, useParams, RouteComponentProps } from 'react-router-dom'
import { Form, FormInstance, message, notification } from 'antd'
import { QueryObserverResult } from 'react-query'
import { useContext, useEffect, useState } from 'react'
import { IUseServicesReturnType, useServices } from '../../services/useServices'
import { messageKeys } from '../../util/en'
import {
  IApiKey,
  IPlatforms,
  IProjectsData,
  TPlatformName,
  TURLNames,
} from '../../types/commonInterfaces'
import { ICopiedProtocols } from './interfaces'
import { ITokens } from '../../types/interfaces'
import { AuthContext, IUserData } from '../../context/AuthContext'
import { isDevelopmentEnvironmentServed } from '../../util/environment'

interface IReturnType extends Partial<IUseServicesReturnType> {
  history: RouteComponentProps['history']
  data: IProjectsData
  isLoading: boolean
  projectId: string
  createProjectApiKey: () => void
  copyUrlCommand: (s: string) => void
  apiKeys: Array<IApiKey & { key: string }>
  copyHttpToClipboard: () => Promise<void>
  copyWssToClipboard: () => Promise<void>
  copyHttpNonArchivalToClipboard: () => Promise<void>

  getUrl: (type: TURLNames) => string | null
  copiedProtocols: ICopiedProtocols
  selectedNetwork: TPlatformName | null
  handleNetworkChange: (s: TPlatformName | null) => void
  handleDeleteApiKey: (s: string) => void
  createdApiKey: IApiKey
  resetApiCreationMutation: (s: string) => void
  copyApiSecretKey: () => Promise<void>
  isCopied: boolean
  isCreationInLoad: boolean
  form: FormInstance
  activeToken: IApiKey
  setActiveToken: (t: ITokens) => void
  resetApiKeyDialog: () => void
  userData: IUserData
  isCommandCopied: boolean
}

export const useProjectDetails = (): IReturnType => {
  const [activeToken, toggleActiveToken] = useState(null)
  const [selectedNetwork, setSelectedNetwork] = useState<TPlatformName | null>(null)
  const [isCopied, setIsCopied] = useState(false)
  const [isCommandCopied, setIsCommandCopied] = useState(false)
  const [copiedProtocols, setCopiedProtocols] = useState<ICopiedProtocols>({
    http: false,
    httpArchived: false,
    ws: false,
  })
  const { region: currentRegion, userData } = useContext(AuthContext)

  const { projectId }: { projectId: string } = useParams()

  const {
    getProject,
    createApiKey,
    deleteProject,
    getApiKeys,
    deleteApiKey,
    setApiKeyName,
  } = useServices()

  const history = useHistory()
  const [form] = Form.useForm()

  // TODO fix type any

  const { data } = getProject(projectId)

  const { data: createdApiKey, isLoading: isCreationInLoad } = createApiKey

  const {
    isLoading,
    error,
    data: apiKeysRaw,
  }: QueryObserverResult<IApiKey[], Error> = getApiKeys(projectId)

  const apiKeys = apiKeysRaw?.map((el: IApiKey) => ({ ...el, key: el.ID }))

  useEffect(() => {
    if (data && data.ProjectPlatforms) {
      setSelectedNetwork(
        data.ProjectPlatforms.filter((el) => el.PlatformName !== 'near')[0]
          .PlatformName
      )
    }
  }, [data])

  useEffect(() => {
    if (error) {
      notification.error({
        message: messageKeys.somethingWentWrong,
        description: error.message,
      })
    }
  }, [error])

  const createProjectApiKey = () => {
    createApiKey.mutate({ projectId })
  }
  const getUrl = (type: TURLNames) => {
    const selectedPlatform = data.ProjectPlatforms.find(
      (el: IPlatforms) => selectedNetwork === el.PlatformName
    )

    if (!selectedPlatform || !selectedPlatform?.ProjectPlatformNetworks?.length) {
      return ''
    }

    const { PlatformName, ProjectPlatformNetworks } = selectedPlatform

    const version = 'v4'
    const network = ProjectPlatformNetworks[0]?.Network || ''
    if (PlatformName === 'soroban' && type === 'WssUrl') {
      return null
    }
    if (PlatformName === 'gnosispay') {
      return `${
        type === 'WssUrl' ? 'wss' : 'https'
      }://rpc.${network}.gnosispay.network${type === 'WssUrl' ? '/ws' : ''}`
    }
    const archivalNonArchival =
      network === 'chiado' ||
      PlatformName === 'zkevm' ||
      PlatformName === 'bnb' ||
      PlatformName === 'palm'
        ? 'archival'
        : 'non-archival'
    let platformInUrl

    switch (PlatformName) {
      case 'zkevm':
        platformInUrl = 'polygon-zkevm'
        break
      default:
        platformInUrl = PlatformName
        break
    }

    return `${type === 'WssUrl' ? 'wss' : 'https'}://rpc.${
      isDevelopmentEnvironmentServed() ? 'dev.' : ''
    }${currentRegion}.gateway.fm/${
      type === 'WssUrl' ? 'ws/' : ''
    }${version}/${platformInUrl}/${archivalNonArchival}/${network}`
  }

  const copyApiSecretKey = async () => {
    try {
      await navigator.clipboard.writeText(
        `${createdApiKey.SecretKey}.${createdApiKey.PublicKey}`
      )
      setIsCopied(true)
      message.success(messageKeys.copySecretToken)
    } catch (err) {
      message.error(messageKeys.somethingWentWrong)
    }
    setTimeout(() => setIsCopied(false), 3000)
  }

  const copyUrlCommand = async (command: string) => {
    try {
      await navigator.clipboard.writeText(command)
      setIsCommandCopied(true)
      message.success(messageKeys.copyCommand)
    } catch (err) {
      message.error(messageKeys.somethingWentWrong)
    }
    setTimeout(() => setIsCommandCopied(false), 3000)
  }

  const copyHttpToClipboard = async (isArchival: boolean | undefined = false) => {
    try {
      await navigator.clipboard.writeText(getUrl('HttpUrl'))
      setCopiedProtocols({ ...copiedProtocols, http: true })
      setTimeout(() => setCopiedProtocols((st) => ({ ...st, http: false })), 3000)
      const successMessage = isArchival
        ? messageKeys.copyHttpArchival
        : messageKeys.copyHttp
      message.success(successMessage, 3)
    } catch (err) {
      message.error(messageKeys.somethingWentWrong, 3)
    }
  }

  const copyHttpNonArchivalToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(getUrl('NonArchivalHttpUrl'))
      setCopiedProtocols({ ...copiedProtocols, httpArchived: true })
      setTimeout(
        () => setCopiedProtocols((st) => ({ ...st, httpArchived: false })),
        3000
      )
      message.success(messageKeys.copyHttpNonArchival, 3)
    } catch (err) {
      message.error(messageKeys.somethingWentWrong, 3)
    }
  }

  const copyWssToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(getUrl('WssUrl'))
      setCopiedProtocols({ ...copiedProtocols, ws: true })
      setTimeout(() => setCopiedProtocols((st) => ({ ...st, ws: false })), 3000)
      message.success(messageKeys.copyWss, 3)
    } catch (err) {
      message.error(messageKeys.somethingWentWrong, 3)
    }
  }

  const handleDeleteApiKey = (apiKeyId: string) => {
    deleteApiKey.mutate({ apiKeyId, projectId })
  }
  const resetApiCreationMutation = (initialName: string) => {
    const { name = '' } = form.getFieldsValue()

    if (initialName !== name.trim()) {
      setApiKeyName.mutate({
        apiKeyId: activeToken ? activeToken.ID : createdApiKey.ID,
        name: name.trim(),
        projectId,
      })
    }

    resetApiKeyDialog()
  }

  const resetApiKeyDialog = () => {
    toggleActiveToken(null)
    form.setFieldsValue({ name: '' })
    createApiKey.reset()
  }

  const handleNetworkChange = (value: TPlatformName) => {
    setSelectedNetwork(value)
  }

  const setActiveToken = (token: ITokens) => {
    toggleActiveToken(token)
    form.setFieldsValue({ name: token.Name })
  }

  return {
    history,
    data,
    isLoading,
    projectId,
    createProjectApiKey,
    copyHttpToClipboard,
    copyHttpNonArchivalToClipboard,
    deleteProject,
    apiKeys,
    copyWssToClipboard,
    handleDeleteApiKey,
    createdApiKey,
    resetApiCreationMutation,
    handleNetworkChange,
    selectedNetwork,
    getUrl,
    copyApiSecretKey,
    isCopied,
    copiedProtocols,
    isCreationInLoad,
    form,
    activeToken,
    setActiveToken,
    resetApiKeyDialog,
    userData,
    copyUrlCommand,
    isCommandCopied,
  }
}
