import React, { FC } from 'react'
import { Descriptions } from 'antd'
import styled from 'styled-components'
import { IProjectsData } from '../../../types/commonInterfaces'
import { dateTimeToLocaleString } from '../../../util/timeConverter'

const DivStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  max-width: 680px;
  min-width: 400px;
  margin-left: 12px;
`

export const DateAndLane: FC<{ projectData: IProjectsData }> = ({ projectData }) => {
  return (
    <DivStyled>
      <Descriptions size="small" column={{ md: 2, sm: 1, xs: 1 }}>
        <Descriptions.Item label="Created on" style={{ paddingBottom: '1px' }}>
          {dateTimeToLocaleString(projectData.CreatedAt)}
        </Descriptions.Item>
        <Descriptions.Item label="Tag" style={{ paddingBottom: '1px' }}>
          {projectData.Lane}
        </Descriptions.Item>
      </Descriptions>
    </DivStyled>
  )
}
