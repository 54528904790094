import React, { FC, ReactNode, useContext } from 'react'
import { useHistory } from 'react-router-dom'

import {
  Table,
  Button,
  Tag,
  Typography,
  Space,
  Empty,
  ConfigProvider,
  TableProps,
  Dropdown,
  MenuProps,
} from 'antd'
import { uniq } from 'lodash'
import { PageHeader } from '@ant-design/pro-layout'
import styled, { createGlobalStyle } from 'styled-components'
import { ColumnsType } from 'antd/es/table'
import { DownOutlined } from '@ant-design/icons'
import { useProjects } from './useProjects'
import { IPlatforms, IProject, IRegion } from '../../types/commonInterfaces'
import { messageKeyDynamic, messageKeys } from '../../util/en'
import { ProjectCreateEditModal } from './components/ProjectCreateEditModal'
import { images } from '../../../assets'
import { EPlatformLabelMap, EPlatformNames, EPlatformTagColors } from './enums'
import IconComponent from '../../components/AppShell/Common/IconComponent'
import LoadingComponent from '../../components/AppShell/Common/LoadingComponent'
import { AuthContext } from '../../context/AuthContext'
import { dateTimeToLocaleString } from '../../util/timeConverter'
import { TableSearchInput } from '../../components/AppShell/Common/TableSearchInput'

const DivStyled = styled.div`
  width: 100%;
  height: 100%;
  cursor: pointer;
  padding: 16px;
  &:hover {
    .name-cell {
      color: #059887;
      text-decoration: underline;
    }
  }
`

const colorMapper = {
  [EPlatformNames.Near]: EPlatformTagColors.Magenta,
  [EPlatformNames.Ethereum]: EPlatformTagColors.Blue,
  [EPlatformNames.Fantom]: EPlatformTagColors.GeekBlue,
  [EPlatformNames.Gnosis]: EPlatformTagColors.Green,
  [EPlatformNames.Zkevm]: EPlatformTagColors.Purple,
  [EPlatformNames.Arbitrum]: EPlatformTagColors.Volcano,
  [EPlatformNames.Soroban]: EPlatformTagColors.Volcano,
  [EPlatformNames.Polygon]: EPlatformTagColors.Cyan,
  [EPlatformNames.Optimism]: EPlatformTagColors.Red,
  [EPlatformNames.Lukso]: EPlatformTagColors.Blue,
  [EPlatformNames.Bnb]: EPlatformTagColors.Yellow,
  [EPlatformNames.Gnosispay]: EPlatformTagColors.Cyan,
  [EPlatformNames.Palm]: EPlatformTagColors.Magenta,
  [EPlatformNames.Base]: EPlatformTagColors.Blue,
}

const ERegionNames = {
  Europe_Sweden: 'Europe (Sweden)',
  Asia_Singapore: 'Asia (Singapore)',
  Europe_Stockholm: 'Europe (Stockholm)',
  Europe_Zurich: 'Europe (Zurich)',
}

const ERegionColors = {
  Aurora: 'magenta',
  Orchid: 'orange',
  Nordic: 'green',
  Alpine: 'volcano',
}

const regionColorMapper = {
  [ERegionNames.Europe_Sweden]: ERegionColors.Aurora,
  [ERegionNames.Asia_Singapore]: ERegionColors.Orchid,
  [ERegionNames.Europe_Stockholm]: ERegionColors.Nordic,
  [ERegionNames.Europe_Zurich]: ERegionColors.Alpine,
}

const CellWrapper: FC<{ id: string; children: ReactNode | ReactNode[] }> = ({
  id,
  children,
}) => {
  const history = useHistory()
  const onClick = () => history.push(`projects/${id}`)
  return <DivStyled onClick={onClick}>{children}</DivStyled>
}

const columnsConstructor = (data: any): ColumnsType<IProject> => {
  const columnsArray = [
    {
      title: 'Name',
      dataIndex: 'Name',
      key: 'name',
      render: (Name: string, record: IProject) => (
        <CellWrapper id={record.ID}>
          <div className="name-cell" data-testid={`${Name}NameWrapper`}>
            {Name}
          </div>
        </CellWrapper>
      ),
    },
    {
      title: 'Platform',
      dataIndex: 'ProjectPlatforms',
      key: 'platform',
      render: (platforms: IPlatforms[], record: IProject) => (
        <CellWrapper id={record.ID}>
          {/* eslint-disable-next-line react/destructuring-assignment */}
          {platforms
            .filter((el) => el.PlatformName !== 'near')
            .map((platform: IPlatforms) => {
              return (
                <Tag
                  color={colorMapper[platform.PlatformName]}
                  key={platform.ID}
                  style={{ height: 22 }}
                  icon={<IconComponent icon={platform.PlatformName} />}
                >
                  {/* eslint-disable-next-line react/destructuring-assignment */}
                  {(platform?.PlatformName &&
                    EPlatformLabelMap[platform.PlatformName]?.toUpperCase()) ||
                    'N/A'}
                </Tag>
              )
            })}
        </CellWrapper>
      ),
    },
    {
      title: 'Region',
      dataIndex: 'region',
      key: 'region',
      filters: uniq(
        data.map((el: any) => {
          return el.region?.name || 'N/A'
        })
      ).map((reg: IRegion) => {
        return {
          text: reg || 'N/A',
          value: reg || 'N/A',
        }
      }),
      filterSearch: true,
      onFilter: (region: string, record: any) => record.region?.name === region,
      render: (reg: IRegion, record: IProject) => (
        <CellWrapper id={record.ID}>
          <Tag color={regionColorMapper[reg?.name]} style={{ border: 'none' }}>
            {reg?.name || 'N/A'}
          </Tag>
        </CellWrapper>
      ),
    },
    {
      title: 'Tag',
      dataIndex: 'Lane',
      key: 'lane',
      render: (lane: string, record: IProject) => (
        <CellWrapper id={record.ID}>
          <div>{lane}</div>
        </CellWrapper>
      ),
    },
    {
      title: 'Created on',
      dataIndex: 'CreatedAt',
      key: 'date',
      render: (date: Date, record: IProject) => (
        <CellWrapper id={record.ID}>{dateTimeToLocaleString(date)}</CellWrapper>
      ),
    },
  ]
  // @ts-ignore
  return columnsArray
}

const { Text } = Typography

const ProjectWrapper = styled.div`
  width: 100%;
`
const DropdownGlobalStyle = createGlobalStyle`
  li.ant-dropdown-menu-item {
    padding: 10px 12px !important;
  }
`
type FlagMapperType = {
  [key: string]: string | undefined
}

const flagMapper: FlagMapperType = {
  'Europe (Sweden)': '🇸🇪',
  'Asia (Singapore)': '🇸🇬',
  'Europe (Stockholm)': '🇸🇪',
  'Europe (Zurich)': '🇨🇭',
  'Europe (Germany)': '🇩🇪',
  'Europe (Frankfurt)': '🇩🇪',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TableWrapper: FC<TableProps<any>> = (props) => (
  <ConfigProvider
    renderEmpty={() => (
      <Empty
        description={messageKeys.emptyProjectsTableText}
        image={images.emptyProjectsTable}
        style={{
          marginTop: '50px',
          marginBottom: '50px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        imageStyle={{
          height: '40px',
          marginBottom: '6px',
        }}
      />
    )}
  >
    <Table {...props} />
  </ConfigProvider>
)
const TableWrapperStyled = styled(TableWrapper)`
  .ant-table-tbody .ant-table-cell {
    padding: 0;
  }
`

const ProjectTitle: FC<{ projectsQty: number; projMaxCount: number }> = ({
  projectsQty,
  projMaxCount,
}) => (
  <Space>
    <Text type="secondary">
      {projectsQty < projMaxCount
        ? messageKeyDynamic.projectLimitsText(projectsQty, projMaxCount)
        : messageKeyDynamic.projectLimitReachedText(projMaxCount)}
    </Text>
  </Space>
)

export const Projects: FC = () => {
  const {
    data,
    isLoading,
    showModal,
    handleCancel,
    isModalVisible,
    form,
    projectFormSubmit,
    projectPlatforms,
    maxProjCount,
    setFilterProjectByName,
    ownProjects,
    onRowClick,
  } = useProjects()
  const { userData } = useContext(AuthContext)
  const { regions } = userData

  const items: MenuProps['items'] =
    regions?.data.map((reg: IRegion) => {
      const supportedPlatformsKey = `supported_platforms_${reg.code}`
      const supportedPlatforms = userData[supportedPlatformsKey]?.split(',') || []
      return {
        label: (
          <a type="link" onClick={() => showModal(reg.code)} id={reg.name}>
            <span style={{ fontWeight: 600, fontSize: '18px' }}>
              {flagMapper[reg.name]} {reg.name}
            </span>
            <br />
            {supportedPlatforms.map((platform: string) => (
              <span key={platform} style={{ marginRight: 8 }}>
                <Tag
                  color={colorMapper[platform as EPlatformNames]}
                  key={platform}
                  style={{ height: 22 }}
                  icon={<IconComponent icon={platform} />}
                >
                  {/* eslint-disable-next-line react/destructuring-assignment */}
                  {(platform &&
                    EPlatformLabelMap[platform as EPlatformNames]?.toUpperCase()) ||
                    'N/A'}
                </Tag>
              </span>
            ))}
          </a>
        ),
        key: reg.code,
      }
    }) || []

  return (
    <ProjectWrapper>
      <PageHeader
        className="site-page-header"
        title={messageKeys.projects}
        tags={
          <ProjectTitle
            projectsQty={ownProjects?.length}
            projMaxCount={+maxProjCount}
          />
        }
        style={{ paddingLeft: 0 }}
        extra={[
          <>
            <Dropdown
              menu={{ items }}
              overlayClassName="custom-dropdown-menu"
              trigger={['click']}
            >
              <Button type="primary" id="createProjBtn">
                <Space>
                  Create project
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
            <DropdownGlobalStyle />
          </>,
        ]}
      />
      <TableSearchInput
        placeHolder="Search for a project name"
        setFilterValue={setFilterProjectByName}
      />
      <TableWrapperStyled
        columns={columnsConstructor(data)}
        dataSource={data}
        rowKey="ID"
        data-testid="projectTable"
        loading={{
          spinning: isLoading,
          indicator: <LoadingComponent />,
        }}
        pagination={false}
        onRow={(record) => {
          return {
            onClick: () => onRowClick(record), // click row
          }
        }}
      />
      {isModalVisible && (
        <ProjectCreateEditModal
          projectData={null}
          isModalVisible={isModalVisible}
          handleCancel={handleCancel}
          form={form}
          projectPlatforms={projectPlatforms}
          projectFormSubmit={projectFormSubmit}
        />
      )}
    </ProjectWrapper>
  )
}
