export const images: { [key: string]: any } = {
  logo: require('./images/logo-grad.png'),
  logoBlack: require('./images/logo-black.png'),
  decor: require('./images/gradient-decot.png'),
  decorsvg: require('./images/blob.svg'),
  decorstsvg: require('./images/blobGrStart.svg'),
  decorensvg: require('./images/blob2.svg'),
  emptyProjectsTable: require('./images/empty.svg'),
  ethereum: require('./images/customIcons/ethereum.svg'),
  near: require('./images/customIcons/near.svg'),
  fantom: require('./images/customIcons/fantom.svg'),
  gnosis: require('./images/customIcons/gnosis.svg'),
  zkevm: require('./images/customIcons/zkevm.svg'),
  polygon: require('./images/customIcons/polygon.png'),
  arbitrum: require('./images/customIcons/arbitrum.png'),
  soroban: require('./images/customIcons/soroban.svg'),
  optimism: require('./images/customIcons/optimism.svg'),
  lukso: require('./images/customIcons/lukso.svg'),
  gnosispay: require('./images/customIcons/gnosispay.png'),
  projectDetailsKey: require('./images/project-key.svg'),
  errorIcon: require('./images/error-icon.svg'),
  questionIcon: require('./images/questionMark.svg'),
  bnb: require('./images/bnb.png'),
  palm: require('./images/customIcons/palm.svg'),
  base: require('./images/customIcons/base.png'),
  // logo: require('./images/color-gr-logo.svg'),
}
