import React, { FC } from 'react'
import { Layout, Menu } from 'antd'
import { FundProjectionScreenOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { images } from '../../../../assets'

const { Sider } = Layout

export const LogoWrapper = styled.div`
  padding: 10px 24px;
  border-bottom: 1px solid white;
  cursor: pointer;
  display: flex;
  align-items: center;
`
export const Logo = styled.img`
  width: 160px;
`

export const Sidebar: FC = () => (
  <Sider trigger={null} collapsible collapsed={false}>
    <LogoWrapper>
      <Logo src={images.logo} alt="Gateway Fm logo" />
    </LogoWrapper>
    <Menu mode="inline" defaultSelectedKeys={['1']}>
      <Menu.Item key="1" icon={<FundProjectionScreenOutlined />}>
        <NavLink data-testid="projects" to="/projects" activeClassName="selected">
          Projects
        </NavLink>
      </Menu.Item>
    </Menu>
  </Sider>
)
