import React from 'react'
import styled from 'styled-components'

interface IStepCard {
  title: string
  description: string
  children?: JSX.Element
}

const WrapperDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;

  svg {
    font-size: 25px;
  }
`
const CardTitle = styled.p`
  font-size: 14px;
  margin: 0;
`
const CardDescription = styled.p`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
  margin: 0;
  text-align: left;
  min-height: 45px;
`
const CardChildrenWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`
export const StepCard = ({ title, description, children }: IStepCard) => {
  return (
    <WrapperDiv>
      <div>
        <CardTitle>{title}</CardTitle>
      </div>
      <div>
        <CardDescription>{description}</CardDescription>
      </div>
      <CardChildrenWrapper>{children}</CardChildrenWrapper>
    </WrapperDiv>
  )
}
