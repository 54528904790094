import React, { FC } from 'react'
import { Radio, Form, RadioChangeEvent } from 'antd'
import { ARRAY_OF_PROJECT_TYPES } from '../../../util/constants'

interface IProps {
  onProjectTypeChange: (type: RadioChangeEvent) => void
}

export const TypeChoosingProjectCreation: FC<IProps> = ({ onProjectTypeChange }) => {
  return (
    <Form.Item label="Project type" tooltip="Choose project type">
      <Radio.Group onChange={onProjectTypeChange} style={{ width: '100%' }}>
        {ARRAY_OF_PROJECT_TYPES.map((platformType: string) => {
          return (
            <Radio
              key={platformType}
              value={platformType}
              data-testid={`${platformType}Checkbox`}
              style={{
                lineHeight: '32px',
              }}
              defaultChecked={false}
            >
              {platformType}
            </Radio>
          )
        })}
      </Radio.Group>
    </Form.Item>
  )
}
