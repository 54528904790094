import React, { FC } from 'react'
import {
  Alert,
  Form,
  Input,
  Modal,
  FormInstance,
  Typography,
  Tabs,
  TabsProps,
  Button,
} from 'antd'
import styled from 'styled-components'
import { messageKeys } from '../../../util/en'
import { colors } from '../../../util/colors'
import { IApiKey } from '../../../types/commonInterfaces'

const curlBuilder = (
  url: string,
  platform: string,
  apiKey: { SecretKey?: string; PublicKey: string }
) => {
  const platformCurl: { [key: string]: string } = {
    ethereum: `curl --location --request POST '${url}?apiKey=${
      apiKey?.SecretKey ? `${apiKey.SecretKey}.${apiKey.PublicKey}` : '<API_KEY>'
    }' \\
 --header 'Content-Type: application/json' \\
 --data-raw '{
      "jsonrpc": "2.0",
      "id": 0,
      "method": "eth_blockNumber"
    }'`,
    gnosis: `curl --location --request POST '${url}?apiKey=${
      apiKey?.SecretKey ? `${apiKey.SecretKey}.${apiKey.PublicKey}` : '<API_KEY>'
    }' \\
 --header 'Content-Type: application/json' \\
 --data-raw '{
      "jsonrpc": "2.0",
      "id": 0,
      "method": "eth_blockNumber"
    }'`,
    fantom: `curl --location --request POST '${url}?apiKey=${
      apiKey?.SecretKey ? `${apiKey.SecretKey}.${apiKey.PublicKey}` : '<API_KEY>'
    }' \\
 --header 'Content-Type: application/json' \\
 --data-raw '{
      "jsonrpc": "2.0",
      "id": 0,
      "method": "eth_blockNumber"
    }'`,
    near: `curl --location --request POST '${url}?apiKey=${
      apiKey?.SecretKey ? `${apiKey.SecretKey}.${apiKey.PublicKey}` : '<API_KEY>'
    }' \\
    --header 'Content-Type: application/json' \\
    --data-raw '{
      "jsonrpc": "2.0",
      "id": "11",
      "method": "block",
      "params": {
        "finality": "final"
      }
    }'`,
    zkevm: `curl --location --request POST '${url}?apiKey=${
      apiKey?.SecretKey ? `${apiKey.SecretKey}.${apiKey.PublicKey}` : '<API_KEY>'
    }' \\
 --header 'Content-Type: application/json' \\
 --data-raw '{
      "jsonrpc": "2.0",
      "id": 0,
      "method": "eth_blockNumber"
    }'`,
    arbitrum: `curl --location --request POST '${url}?apiKey=${
      apiKey?.SecretKey ? `${apiKey.SecretKey}.${apiKey.PublicKey}` : '<API_KEY>'
    }' \\
 --header 'Content-Type: application/json' \\
 --data-raw '{
      "jsonrpc": "2.0",
      "id": 0,
      "method": "eth_blockNumber"
    }'`,
    soroban: `curl --location --request POST '${url}?apiKey=${
      apiKey?.SecretKey ? `${apiKey.SecretKey}.${apiKey.PublicKey}` : '<API_KEY>'
    }' \\
 --header 'Content-Type: application/json' \\
 --data-raw '{
        "jsonrpc": "2.0",
        "method": "getHealth",
        "id": 0
        }'`,
    lukso: `curl --location --request POST '${url}?apiKey=${
      apiKey?.SecretKey ? `${apiKey.SecretKey}.${apiKey.PublicKey}` : '<API_KEY>'
    }' \\
 --header 'Content-Type: application/json' \\
 --data-raw '{
      "jsonrpc": "2.0",
      "id": 0,
      "method": "eth_blockNumber"
    }'`,
    bnb: `curl --location --request POST '${url}?apiKey=${
      apiKey?.SecretKey ? `${apiKey.SecretKey}.${apiKey.PublicKey}` : '<API_KEY>'
    }' \\
 --header 'Content-Type: application/json' \\
 --data-raw '{
      "jsonrpc": "2.0",
      "id": 0,
      "method": "eth_blockNumber"
    }'`,
    palm: `curl --location --request POST '${url}?apiKey=${
      apiKey?.SecretKey ? `${apiKey.SecretKey}.${apiKey.PublicKey}` : '<API_KEY>'
    }' \\
 --header 'Content-Type: application/json' \\
 --data-raw '{
      "jsonrpc": "2.0",
      "id": 0,
      "method": "eth_blockNumber"
    }'`,
    base: `curl --location --request POST '${url}?apiKey=${
      apiKey?.SecretKey ? `${apiKey.SecretKey}.${apiKey.PublicKey}` : '<API_KEY>'
    }' \\
 --header 'Content-Type: application/json' \\
 --data-raw '{
      "jsonrpc": "2.0",
      "id": 0,
      "method": "eth_blockNumber"
    }'`,
    polygon: `curl --location --request POST '${url}?apiKey=${
      apiKey?.SecretKey ? `${apiKey.SecretKey}.${apiKey.PublicKey}` : '<API_KEY>'
    }' \\
 --header 'Content-Type: application/json' \\
 --data-raw '{
      "jsonrpc": "2.0",
      "id": 0,
      "method": "eth_blockNumber"
    }'`,
    optimism: `curl --location --request POST '${url}?apiKey=${
      apiKey?.SecretKey ? `${apiKey.SecretKey}.${apiKey.PublicKey}` : '<API_KEY>'
    }' \\
 --header 'Content-Type: application/json' \\
 --data-raw '{
      "jsonrpc": "2.0",
      "id": 0,
      "method": "eth_blockNumber"
    }'`,
    gnosispay: `curl --location --request POST '${url}?apiKey=${
      apiKey?.SecretKey ? `${apiKey.SecretKey}.${apiKey.PublicKey}` : '<API_KEY>'
    }' \\
 --header 'Content-Type: application/json' \\
 --data-raw '{
      "jsonrpc": "2.0",
      "id": 0,
      "method": "eth_blockNumber"
    }'`,
  }
  return platformCurl[platform]
}

const KeyStyled = styled.p`
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 6px;
  padding: 4px 11px;
  margin: 0 24px;
  width: 474px;
  cursor: copy;
  transition: width 2s linear 1s;
`
const KeyWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`
const KeyLabel = styled.p`
  margin: 0;
`
const ApiKeyUseTitleStyled = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 16px;
`

interface IProps {
  createdApiKey: IApiKey
  resetApiCreationMutation: (s: string) => void
  form: FormInstance
  copyApiSecretKey: () => void
  isCopied: boolean
  resetApiKeyDialog: () => void
  getUrl: (s: string) => string
  selectedNetwork: string
  urlWithAuthHeaderBuilder: (
    s: string,
    se: string,
    k: { SecretKey?: string; PublicKey: string }
  ) => string
  copyUrlCommand: (s: string) => void
  isCommandCopied: boolean
}

const { Paragraph } = Typography

export const ApiKeyDetailsDialog: FC<IProps> = ({
  createdApiKey,
  resetApiCreationMutation,
  form,
  copyApiSecretKey,
  isCopied,
  resetApiKeyDialog,
  selectedNetwork,
  getUrl,
  urlWithAuthHeaderBuilder,
  copyUrlCommand,
  isCommandCopied,
}) => {
  const isCreateMode = createdApiKey && !!createdApiKey.SecretKey

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: `Bearer Token (Recommended)`,
      children: (
        <Paragraph
          style={{
            textAlign: 'left',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <pre style={{ position: 'relative' }}>
            {urlWithAuthHeaderBuilder(
              getUrl('HttpUrl') || getUrl('NonArchivalHttpUrl'),
              selectedNetwork,
              createdApiKey
            )}
            <Button
              style={{ position: 'absolute', right: 8, bottom: 8 }}
              onClick={() => {
                copyUrlCommand(
                  urlWithAuthHeaderBuilder(
                    getUrl('HttpUrl') || getUrl('NonArchivalHttpUrl'),
                    selectedNetwork,
                    createdApiKey
                  )
                )
              }}
            >
              {isCommandCopied ? 'Copied' : 'Copy'}
            </Button>
          </pre>
        </Paragraph>
      ),
    },
    {
      key: '2',
      label: `GET parameter`,
      children: (
        <Paragraph
          style={{
            textAlign: 'left',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <pre style={{ position: 'relative' }}>
            {curlBuilder(
              getUrl('HttpUrl') || getUrl('NonArchivalHttpUrl'),
              selectedNetwork,
              createdApiKey
            )}
            <Button
              style={{ position: 'absolute', right: 8, bottom: 8 }}
              onClick={() => {
                copyUrlCommand(
                  curlBuilder(
                    getUrl('HttpUrl') || getUrl('NonArchivalHttpUrl'),
                    selectedNetwork,
                    createdApiKey
                  )
                )
              }}
            >
              {isCommandCopied ? 'Copied' : 'Copy'}
            </Button>
          </pre>
        </Paragraph>
      ),
    },
  ]

  return (
    <div>
      {createdApiKey && (
        <Modal
          visible
          title={isCreateMode ? messageKeys.apiKeyCreated : messageKeys.editApiKey}
          cancelButtonProps={{ hidden: !!createdApiKey.SecretKey }}
          onOk={() => resetApiCreationMutation(createdApiKey.Name)}
          onCancel={() => resetApiKeyDialog()}
          width={642}
          closable={!isCreateMode}
          okText={
            <span data-testid="createEditApiKeyConfirmBtn">
              {isCreateMode ? 'Ok' : 'Save'}
            </span>
          }
          cancelText={<span data-testid="createEditApiKeyCancelBtn">Cancel</span>}
        >
          {createdApiKey.SecretKey && (
            <KeyWrapperStyled>
              <KeyLabel>Key:</KeyLabel>
              <KeyStyled
                data-testid="apiKeyValue"
                onClick={copyApiSecretKey}
              >{`${createdApiKey.SecretKey}.${createdApiKey.PublicKey}`}</KeyStyled>
              <Button onClick={copyApiSecretKey} data-testid="apiKeyWrapper">
                {isCopied ? (
                  <span
                    style={{
                      color: colors.gateway_green,
                    }}
                  >
                    Copied
                  </span>
                ) : (
                  <span
                    style={{
                      color: colors.gateway_green,
                    }}
                  >
                    Copy
                  </span>
                )}
              </Button>
            </KeyWrapperStyled>
          )}
          <Form
            name="apiKey"
            form={form}
            layout="horizontal"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 18 }}
            data-testid="apiCreationModal"
          >
            <Form.Item label="Alias" name="name" labelAlign="left">
              <Input
                data-testid="apiKeyNameInput"
                style={{ marginLeft: '-48px', width: 'calc(100% + 7px)' }}
              />
            </Form.Item>
          </Form>
          <ApiKeyUseTitleStyled>How to use api key?</ApiKeyUseTitleStyled>
          <Tabs defaultActiveKey="1" items={items} />
          {createdApiKey.SecretKey && (
            <Alert description={messageKeys.makeCopy} type="warning" showIcon />
          )}
        </Modal>
      )}
    </div>
  )
}
