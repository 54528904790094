import styled from 'styled-components'
import React from 'react'

export const EnvTag = styled.div`
  width: 50px;
  height: 20px;
  align-items: center;
  padding: 0;
  border-radius: 10px;
  color: #f7f6f2;
  background-color: black;
  font-weight: 500;
  display: flex;
  justify-content: center;
  margin-left: 10px;
`

export const DevTag = () => <EnvTag>DEV</EnvTag>
