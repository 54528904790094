import React, { createContext, FC, ReactNode, useContext } from 'react'
import axios from 'axios'
import { useKeycloak } from '@react-keycloak/web'
import { AuthContext } from './AuthContext'
import { EMPTY_REGION, REGION_SUBDOMAIN } from '../util/constants'
import { toggleMultiRegionEnabled } from '../util/environment'

const FetchContext = createContext(null)
const { Provider } = FetchContext

interface IProps {
  children: ReactNode | ReactNode[]
}

const FetchProvider: FC<IProps> = ({ children }) => {
  const { keycloak } = useKeycloak()
  const { region } = useContext(AuthContext)

  let baseURL = process.env.API_BASE_URL || 'http://localhost:8080/v1'

  if (toggleMultiRegionEnabled() && region !== EMPTY_REGION) {
    baseURL = process.env.API_BASE_URL_DYNAMIC
      ? process.env.API_BASE_URL_DYNAMIC.replace(REGION_SUBDOMAIN, region)
      : 'http://localhost:8080/v1'
    // for local dev testing purposes
    // baseURL =
    //   region === 'eu-central-1'
    //     ? process.env.API_BASE_URL_DYNAMIC.replace(REGION_SUBDOMAIN, '8901')
    //     : process.env.API_BASE_URL_DYNAMIC.replace(REGION_SUBDOMAIN, '8080')
  }

  const authAxios = axios.create({ baseURL })

  axios.interceptors.response.use((response) => response.data)
  authAxios.interceptors.response.use(
    (response) => response,
    (error) => {
      const code = error && error.response ? error.response.status : 0
      if (code === 401) {
        keycloak.logout()
      }
      return Promise.reject(error)
    }
  )

  authAxios.interceptors.request.use(
    (config) => {
      const newConfig = { ...config }
      newConfig.headers.Authorization = keycloak.token
      return newConfig
    },
    (error) => Promise.reject(error)
  )
  authAxios.interceptors.response.use(
    (response) => response,
    (error) => {
      const code = error && error.response ? error.response.status : 0
      if (code === 401) {
        keycloak.logout()
      }
      return Promise.reject(error)
    }
  )

  const metricsBaseUrl =
    region !== EMPTY_REGION && region
      ? process.env.METRICS_API_BASE_URL_DYNAMIC.replace(REGION_SUBDOMAIN, region)
      : process.env.METRICS_API_BASE_URL

  return (
    <Provider
      value={{
        authAxios,
        metricsBaseUrl,
      }}
    >
      {children}
    </Provider>
  )
}

export { FetchContext, FetchProvider }
