import React, { FC } from 'react'
import Icon from '@ant-design/icons'
import styled from 'styled-components'
import { images } from '../../../../assets'

const ImgStyled = styled.img`
  margin-bottom: 5px;
`

const IconComponent: FC<{ icon: string }> = ({ icon }) => {
  return (
    <Icon
      component={() => (
        <ImgStyled src={images[icon]} alt="icon" style={{ width: '12px' }} />
      )}
    />
  )
}

export default IconComponent
