import React, { FC } from 'react'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import 'antd/dist/reset.css'
import { QueryClient, QueryClientProvider, setLogger } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { ConfigProvider } from 'antd'
import keycloak from './keycloak'
import { AppRoutes } from './routes/AppRoutes'
import { AuthProvider } from './context/AuthContext'
import { FetchProvider } from './context/FetchContext'
import { isDevEnvironment } from './util/environment'
import OrganizationMissingModal from './components/AppShell/Common/OrganizationMissingModal'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { retry: false },
    mutations: { retry: false },
  },
})

// @ts-ignore
setLogger({
  error(error): void {
    if (isDevEnvironment()) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  },
})

const App: FC = () => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#059887',
          controlItemBgActive: 'rgba(0, 0, 0, 0.06)',
          colorBgLayout: '#fff',
        },
      }}
    >
      <QueryClientProvider client={queryClient}>
        <ReactKeycloakProvider
          initOptions={{ onLoad: 'login-required' }}
          authClient={keycloak}
          autoRefreshToken
        >
          <AuthProvider>
            <FetchProvider>
              <div className="bg-gray-100 wrapper">
                <OrganizationMissingModal />
                <AppRoutes />
              </div>
            </FetchProvider>
          </AuthProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </ReactKeycloakProvider>
      </QueryClientProvider>
    </ConfigProvider>
  )
}

export default App
