import { SUPPORT_MAIL } from './constants'
import { messageKeys } from './en'

const mailToGenerator = (requestId = ''): string => {
  if (!requestId) {
    return `mailto: ${SUPPORT_MAIL}`
  }
  return `mailto: ${SUPPORT_MAIL}?subject=${messageKeys.somethingWentWrongErr} on gateway.fm&body=Request ID: ${requestId}`
}

export default mailToGenerator
