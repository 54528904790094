import React, {
  createContext,
  useCallback,
  FC,
  ReactNode,
  useEffect,
  useState,
} from 'react'
import { useKeycloak } from '@react-keycloak/web'
import { IRegion, IRegionsData } from '../types/commonInterfaces'
import {
  getRegionFromLocalStorage,
  setRegionToLocalStorage,
} from '../services/localStorage'
import { toggleMultiRegionEnabled } from '../util/environment'
import LoadingComponent from '../components/AppShell/Common/LoadingComponent'
import { EMPTY_REGION } from '../util/constants'

const AuthContext = createContext({
  userData: null,
  token: null,
  logout: () => null,
  getUserInfo: () => null,
  keycloak: null,
  region: null,
  changeRegion: (r: string) => {
    // eslint-disable-next-line no-console
    console.log('changeRegion', r)
    // tmp, it is just for avoiding TS complains
  },
})
const { Provider } = AuthContext

export interface IUserData {
  orgs: string[]
  email: string
  email_verified: boolean
  family_name: string
  given_name: string
  name: string
  preferred_username: string
  sub: string
  regions: IRegionsData
  getUserInfo: () => void
}

const AuthProvider: FC<{ children: ReactNode | ReactNode[] }> = ({ children }) => {
  const { keycloak, initialized } = useKeycloak()
  const [token, setToken] = useState<string | null>(null)
  const [userData, setUserData] = useState<IUserData | null>(null)
  const [region, setRegion] = useState<string | null>(null)
  const getUserInfo = () => {
    keycloak
      .loadUserInfo()
      .then((authUser: IUserData) => {
        setUserData(authUser)
        setToken(keycloak.token)
        if (toggleMultiRegionEnabled()) {
          if (authUser.regions?.data?.length) {
            initRegion(authUser.regions)
          } else {
            setRegion(EMPTY_REGION)
          }
        }
      })
      .catch(() => {
        logout()
      })
  }
  useEffect(() => {
    if (initialized) {
      getUserInfo()
    }
  }, [initialized, keycloak.token])

  const initRegion = useCallback(
    (regionsData: IRegionsData) => {
      const regionsArray = regionsData?.data
      if (regionsArray?.length) {
        const lastRegion = getRegionFromLocalStorage()
        if (lastRegion) {
          const appropriateRegion = regionsArray.find(
            (regionElement: IRegion) => regionElement.code === lastRegion
          )
          if (appropriateRegion) {
            setRegion(lastRegion)
          }
        } else {
          setRegion(regionsArray[0].code)
        }
      }
    },
    [initialized]
  )

  const changeRegion = (newRegion: string) => {
    setRegionToLocalStorage(newRegion)
    setRegion(newRegion)
  }

  const logout = () => {
    localStorage.removeItem('region')
    localStorage.removeItem('data-region')
    keycloak.logout({ redirectUri: window.location.origin })
  }
  return (
    <Provider
      value={{
        userData,
        token,
        logout,
        keycloak,
        region,
        changeRegion,
        getUserInfo,
      }}
    >
      {!toggleMultiRegionEnabled() || region ? children : <LoadingComponent />}
    </Provider>
  )
}

export { AuthContext, AuthProvider }
