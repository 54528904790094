import { EProjectEnvironment, EProjectType } from '../pages/projects/enums'

export const SUPPORT_MAIL = 'support@gateway.fm'

export const ARRAY_OF_PROJECT_TYPES = [EProjectType.Rpc]

export const ENVIRONMENTS_LANE = [EProjectEnvironment.dev, EProjectEnvironment.prod]

export const REGION_SUBDOMAIN = '{region_code}'

export const EMPTY_REGION = 'emptyRegion'

export const SUCCESS_FAILURE_METRICS_INITIAL = {
  Success1Day: 0,
  Failure1Day: 0,
  Success7Day: 0,
  Failure7Day: 0,
}

export const WEBSOCKET_METRICS_INITIAL = {
  Read1Day: 0,
  Sent1Day: 0,
  Sent7Day: 0,
  Read7Day: 0,
}
