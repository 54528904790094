import React, { createContext, Component, ReactNode } from 'react'
import { IRequestData } from '../types/commonInterfaces'

interface IErrorContext {
  triggerError: (e: string, requestData?: null | IRequestData) => void
  resetError: () => void
  error: boolean
  errorMessage: string
  requestData: null | IRequestData
}

const errorContextInitial: IErrorContext = {
  triggerError: () => null,
  resetError: () => null,
  error: false,
  errorMessage: 'string',
  requestData: null,
}

const ErrorContext = createContext<IErrorContext>(errorContextInitial)

const { Provider } = ErrorContext

interface IProps {
  children: ReactNode | ReactNode[]
}

interface IState {
  error: boolean
  errorMessage: string
  requestData: IRequestData | null
}

class ErrorProvider extends Component<IProps, IState> {
  state: IState = {
    error: false,
    errorMessage: '',
    requestData: null,
  }

  static getDerivedStateFromError(error: Error): {
    error: boolean
    errorMessage: string
  } {
    return {
      error: true,
      errorMessage: error.message,
    }
  }

  triggerError = (
    errorMessage: string,
    requestData: null | IRequestData = null
  ): void => {
    this.setState({ error: true, errorMessage, requestData })
  }

  resetError = (): void =>
    this.setState({ error: false, errorMessage: '', requestData: null })

  render(): JSX.Element {
    const { children } = this.props
    const { error, errorMessage, requestData } = this.state

    return (
      <Provider
        value={{
          error,
          errorMessage,
          requestData,
          resetError: this.resetError,
          triggerError: this.triggerError,
        }}
      >
        {children}
      </Provider>
    )
  }
}

const useErrorContext = (): IErrorContext => {
  return React.useContext<IErrorContext>(ErrorContext)
}

export { ErrorContext, ErrorProvider, useErrorContext }
