import React, { FC } from 'react'
import { Route, Switch } from 'react-router-dom'
import { GlobalErrorComponentWrapper } from '../components/AppShell/Common/GlobalErrorComponentWrapper'
import { Login } from '../pages/login'

export const UnauthRoutes: FC = () => (
  <Switch>
    <Route exact path="/">
      <Login />
    </Route>
    <Route path="*">
      <GlobalErrorComponentWrapper
        errorMessagePassed="404"
        is404
        resetFunctionSkip
      />
    </Route>
  </Switch>
)
