import React, { FC, ReactNode, useContext } from 'react'
import { Layout } from 'antd'
import { HeaderFooterLayout } from './components/HeaderFooter'
import { AuthContext } from '../../context/AuthContext'
import LoadingComponent from './Common/LoadingComponent'
import { ErrorProvider } from '../../context/ErrorContext'
import { GlobalErrorComponentWrapper } from './Common/GlobalErrorComponentWrapper'

interface IProps {
  children: ReactNode | ReactNode[]
}

export const AppShell: FC<IProps> = ({ children }) => {
  const { userData } = useContext(AuthContext)
  return (
    <Layout style={{ minHeight: '100vh' }}>
      {userData ? (
        <>
          {/* <Sidebar /> */}
          <ErrorProvider>
            <HeaderFooterLayout>
              <GlobalErrorComponentWrapper>{children}</GlobalErrorComponentWrapper>
            </HeaderFooterLayout>
          </ErrorProvider>
        </>
      ) : (
        <LoadingComponent />
      )}
    </Layout>
  )
}
