import { Button, Image, Typography, Space } from 'antd'
import React, { FC, ReactNode } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { images } from '../../../../assets'
import { messageKeys } from '../../../util/en'
import { useErrorContext } from '../../../context/ErrorContext'
import mailToGenerator from '../../../util/mailToGenerator'

const { Link, Text } = Typography

const PositioningDiv = styled.div`
  height: calc(100% - 160px);
  position: absolute;
  width: 100%;
`

const WrapperDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`
const TitleText = styled.p`
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 0;
`

const TextWrapperDiv = styled.div`
  margin: 30px 0;
  text-align: center;
`

const ButtonMimicPrimary = styled(Button)`
  background-color: #1890ff;
  border-color: #1890ff;
  &:hover {
    background-color: #40a9ff;
    border-color: #40a9ff;
  }
`

interface IProps {
  children?: ReactNode | ReactNode[]
  errorMessagePassed?: string
  resetFunctionSkip?: boolean
  is404?: boolean
}

export const GlobalErrorComponentWrapper: FC<IProps> = ({
  children,
  resetFunctionSkip,
  errorMessagePassed,
  is404,
}) => {
  const history = useHistory()
  const { errorMessage, resetError, error, requestData } = useErrorContext()
  const errorMessageToShow = errorMessagePassed || errorMessage

  const onBack = () => {
    if (!resetFunctionSkip) {
      resetError()
    }
    history.goBack()
  }

  if (!error && !is404) {
    return <div>{children}</div>
  }
  return (
    <PositioningDiv>
      <WrapperDiv>
        <Image src={images.errorIcon} width={70} preview={false} />
        <TextWrapperDiv>
          <TitleText>{messageKeys.somethingWentWrongErr}</TitleText>
          <Space direction="vertical">
            {requestData && (
              <Text type="secondary">
                {errorMessageToShow} ({requestData.requestId})
              </Text>
            )}
            {is404 && <Text type="secondary">{messageKeys.error404}</Text>}
            <Text>
              Contact our support team at &nbsp;
              <Link href={mailToGenerator(requestData?.requestId)} target="_blank">
                support@gateway.fm
              </Link>
            </Text>
          </Space>
        </TextWrapperDiv>
        <ButtonMimicPrimary onClick={onBack} type="primary">
          {messageKeys.goBack}
        </ButtonMimicPrimary>
      </WrapperDiv>
    </PositioningDiv>
  )
}
