import { AxiosInstance } from 'axios'
import {
  IApiKey,
  IPlatformsWithNetwork,
  IProjectForUpdate,
  IProjectsData,
  IMetric,
  ISuccessFailMetric,
  IWebsocketMetrics,
  IOrganizationMetrics,
} from '../types/commonInterfaces'
import { REGION_SUBDOMAIN } from '../util/constants'

export const getProjectPlatformsApi = async (
  authAxios: AxiosInstance
): Promise<IPlatformsWithNetwork> => {
  const { data: response }: { data: { data: IPlatformsWithNetwork } } =
    await authAxios.get('/projects/platforms')
  return response.data
}

export const createProjectApi = async (
  authAxios: AxiosInstance,
  body: { name: string; platforms: IPlatformsWithNetwork; lane: string }
): Promise<IProjectsData> => {
  const { data: response }: { data: { data: IProjectsData } } = await authAxios.post(
    '/projects',
    body
  )
  return response.data
}

export const getProjectsApi = async (
  authAxios: AxiosInstance,
  regions: string[]
): Promise<IProjectsData[]> => {
  const projectIds = new Set<string>()

  const allProjects = await Promise.all(
    regions.map(async (region: any) => {
      const baseUrl = process.env.API_BASE_URL_DYNAMIC.replace(
        REGION_SUBDOMAIN,
        region.code
      )

      const { data: response }: { data: { data: IProjectsData[] } } =
        await authAxios.get(`/projects`, { baseURL: baseUrl })

      const projs = response.data || []
      return projs.map((project: IProjectsData) => ({
        ...project,
        region,
      }))
    })
  )

  const uniqueProjects: IProjectsData[] = allProjects.flat().filter((project) => {
    if (!projectIds.has(project.ID)) {
      projectIds.add(project.ID)
      return true
    }
    return false
  })

  return uniqueProjects
}

export const getProjectApi = async (
  authAxios: AxiosInstance,
  projectId: string
): Promise<IProjectsData> => {
  const { data: response }: { data: { data: IProjectsData } } = await authAxios.get(
    `/projects/${projectId}`
  )

  return response.data
}

export const updateProjectApi = async (
  authAxios: AxiosInstance,
  body: IProjectForUpdate
): Promise<IProjectsData> => {
  const { data: response }: { data: { data: IProjectsData } } =
    await authAxios.patch(`/projects/${body.projectId}`, body)
  return response.data
}

export const deleteProjectApi = async (
  authAxios: AxiosInstance,
  projectId: string
): Promise<string> => {
  const { data: response }: { data: { data: string } } = await authAxios.delete(
    `/projects/${projectId}`
  )
  return response.data
}

export const createKeyApi = async (
  authAxios: AxiosInstance,
  body: { projectId: string }
): Promise<IApiKey> => {
  const { data: response }: { data: { data: IApiKey } } = await authAxios.post(
    '/apiKeys',
    body
  )
  return response.data
}

export const getKeysApi = async (
  authAxios: AxiosInstance,
  projectId: string
): Promise<IApiKey[]> => {
  const { data: response }: { data: { data: IApiKey[] } } = await authAxios.get(
    `/apiKeys?projectId=${projectId}`
  )
  return response.data
}

export const getProjectMetricsApi = async (
  authAxios: AxiosInstance,
  partnerId: string,
  metricsBaseUrl: string
): Promise<IMetric> => {
  const { data: response }: { data: IMetric } = await authAxios({
    url: `/metrics/partnerId/${partnerId}`,
    baseURL: metricsBaseUrl,
  })

  return response
}

export const getProjectSuccessFailMetricsApi = async (
  authAxios: AxiosInstance,
  projectId: string,
  metricsBaseUrl: string
): Promise<ISuccessFailMetric> => {
  const { data: response }: { data: ISuccessFailMetric } = await authAxios({
    url: `/metrics/success-fail/${projectId}`,
    baseURL: metricsBaseUrl,
  })

  return response
}

export const getProjectWebsocketMetricsApi = async (
  authAxios: AxiosInstance,
  projectId: string,
  metricsBaseUrl: string
): Promise<IWebsocketMetrics> => {
  const { data: response }: { data: IWebsocketMetrics } = await authAxios({
    url: `/metrics/websockets/${projectId}`,
    baseURL: metricsBaseUrl,
  })

  return response
}

export const deleteKeyApi = async (
  authAxios: AxiosInstance,
  apiKeyId: string
): Promise<string> => {
  const { data: response }: { data: { data: string } } = await authAxios.delete(
    `/apiKeys/${apiKeyId}`
  )
  return response.data
}

export const setKeyNameApi = async (
  authAxios: AxiosInstance,
  apiKeyId: string,
  name: string
): Promise<{ ID: string }> => {
  const { data: response }: { data: { data: { ID: string } } } =
    await authAxios.patch(`/apiKeys/${apiKeyId}`, { name })
  return response.data
}

export const getOrganizationMetricsApi = async (
  authAxios: AxiosInstance,
  name: string
): Promise<IOrganizationMetrics[]> => {
  const { data: response }: { data: { data: IOrganizationMetrics[] } } =
    await authAxios({
      url: `/organization/meta/${name}`,
    })
  return response.data
}

export const deleteAccountApi = async (
  authAxios: AxiosInstance,
  id: string
): Promise<string> => {
  const { data: response }: { data: { data: string } } = await authAxios.delete(
    `/account/${id}`
  )
  return response.data
}
