/* eslint-disable default-case */
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import { Form, FormInstance, notification } from 'antd'
import { useServices } from '../../services/useServices'
import { messageKeys } from '../../util/en'
import {
  INewProjectFormValues,
  IPlatformsArray,
  IPlatformsWithNetwork,
  IProjectsData,
  TNetworkLabel,
  TPlatformName,
} from '../../types/commonInterfaces'
import { AuthContext } from '../../context/AuthContext'

interface IUseProjectsReturnType {
  showModal: (reg: string) => void
  onRowClick: (a: any) => void
  hideModal: () => void
  handleCancel: () => void
  data: IProjectsData[]
  ownProjects: IProjectsData[]
  isLoading: boolean
  error: Error
  isModalVisible: boolean
  form: FormInstance
  projectFormSubmit: (pd: IProjectsData) => void
  handleDeleteProject: (ID: string) => void
  projectPlatforms: IPlatformsWithNetwork
  onPlatformChange: (s: string[]) => void
  selectedPlatform: string[]
  maxProjCount: string
  region: string
  setFilterProjectByName: Dispatch<SetStateAction<string>>
}
export interface IRegion {
  code: string
  name: string
}

export const useProjects = (): IUseProjectsReturnType => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const [filterProjectByName, setFilterProjectByName] = useState('')
  const [selectedPlatform, setSelectedPlatforms] = useState<string[]>([])

  const {
    getProjects,
    createProject,
    deleteProject,
    getProjectPlatforms,
    updateProject,
    getOrganizationMetrics,
  } = useServices()

  const [form] = Form.useForm()
  const { region, userData, changeRegion } = useContext(AuthContext)

  const {
    isFetching,
    error,
    data,
    refetch: refetchProjects,
  } = getProjects(userData?.regions?.data)

  const { data: projectPlatforms, refetch: refetchPlatforms } = getProjectPlatforms()
  const { data: orgMetrics } = getOrganizationMetrics()
  const maxProjCount =
    orgMetrics?.filter((el) => el.Key === 'proj-max-count')[0]?.Value || '100'
  useEffect(() => {
    if (error) {
      notification.error({
        message: messageKeys.somethingWentWrong,
        description: error.message,
      })
    }
  }, [error])

  useEffect(() => {
    refetchPlatforms()
    refetchProjects()
  }, [region])

  const showModal = (reg: string) => {
    changeRegion(reg)
    // queryClient.invalidateQueries('projectPlatforms')
    setIsModalVisible(true)
  }

  const hideModal = () => {
    setIsModalVisible(false)
  }

  const handleOk = (values: INewProjectFormValues, projectId: string) => {
    const platforms: Array<{ name: TPlatformName; networks: TNetworkLabel[] }> = []
    const { lane } = values
    const sortedPlatforms = [...values.platformsArray]
    sortedPlatforms.sort((a, b) => a.platform[0].localeCompare(b.platform[0]))
    sortedPlatforms.forEach((platform) => {
      platforms.push({
        name: platform.platform,
        networks: [platform.network],
      })
    })
    // todo change backend to accept network string not array

    if (projectId) {
      updateProject.mutate({
        name: values.name,
        lane,
        platforms,
        projectId,
      })
    } else {
      createProject.mutate({ name: values.name, platforms, lane })
    }
  }

  const projectFormSubmit = (projectData: IProjectsData) => {
    form
      .validateFields()
      .then((values: INewProjectFormValues) => {
        form.setFieldsValue({})
        setSelectedPlatforms([])
        const valuesPlatforms = values.platformsArray
          .filter((el: IPlatformsArray) => {
            return !projectData?.ProjectPlatforms?.some((elm) => {
              // @ts-ignore
              return elm.PlatformName === el.platform.value
            })
          })
          .map((el) => {
            // @ts-ignore
            return { ...el, platform: el.platform.value }
          })
        handleOk({ ...values, platformsArray: valuesPlatforms }, projectData.ID)
        hideModal()
      })
      .catch((e) => {
        console.error(e)
      })
  }
  const onPlatformChange = (value: string[]) => {
    setSelectedPlatforms(value)
  }

  const handleCancel = () => {
    form.resetFields()
    setSelectedPlatforms([])
    hideModal()
  }

  const handleDeleteProject = (projectId: string) => {
    deleteProject.mutate(projectId)
  }

  const projectsDataManipulated = data
    ? data.filter((el) =>
        el.Name.toLowerCase().includes(filterProjectByName.toLowerCase())
      )
    : []

  const ownProjects = data?.filter((el) => el.OwnerId === userData.sub) ?? []
  const onRowClick = (record: any) => {
    changeRegion(record.region.code)
  }
  return {
    showModal,
    handleCancel,
    data: projectsDataManipulated,
    ownProjects,
    isLoading: isFetching,
    error,
    isModalVisible,
    form,
    projectFormSubmit,
    handleDeleteProject,
    projectPlatforms,
    onPlatformChange,
    selectedPlatform,
    hideModal,
    maxProjCount,
    setFilterProjectByName,
    region,
    onRowClick,
  }
}
