import { messageKeyDynamic, messageKeys } from './en'

export const titleCreationHelper = (
  isEdit: boolean,
  isInitialStep: boolean,
  title: string
) => {
  if (isEdit) {
    return messageKeys.updateProj
  }
  if (isInitialStep) {
    return messageKeys.createProjChooseTypeTitle
  }

  return title
    ? messageKeyDynamic.createProjectTitle(title)
    : messageKeys.createProjTitle
}
