import React, { FC } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import styled from 'styled-components'

const DivStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
`

const LoadingComponent: FC = () => {
  const antIcon = (
    <LoadingOutlined
      style={{ fontSize: 36, color: 'rgba(0, 0, 0, 0.25)', fontWeight: 'bold' }}
      spin
    />
  )
  return (
    <DivStyled>
      <Spin indicator={antIcon} />
    </DivStyled>
  )
}

export default LoadingComponent
