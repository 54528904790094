import React, { FC, useContext, useEffect, useState } from 'react'
import { FetchContext } from '../../context/FetchContext'

export const Version: FC = () => {
  const [backVersion, setVersion] = useState('')
  const { authAxios } = useContext(FetchContext)

  useEffect(() => {
    authAxios
      .get('/version')
      .then((res: { data: string }) => {
        setVersion(res.data)
      })
      .catch(() => {
        setVersion('err')
      })
  }, [])

  return (
    <>
      <div>ui - {process.env.VERSION}</div>
      <div>back - {backVersion} </div>
    </>
  )
}
